<ng-template #loading>
    <div class="col-12 text-center">Loading...</div>
</ng-template>
<div *ngIf="$notificationLogMetaData | async as notificationData ; else loading">
    <div class="row">
        <label class="panel-title">{{ 'NOTIFICATION_SEARCH_TEXT' | translate }}</label>
    </div>
    <div class="card main-card">
        <div>
            <form [formGroup]="notificationLogSearchForm" class="form-horizontal register-form" novalidate>

                <div *ngIf="showZeroResultsMessage" class="row">
                    <div class="col-xs-12 error-block">
                        <span class="error-block">{{ 'NO_NOTIFICATIONS_FOUND' | translate }}</span>
                    </div>
                </div>
                <div *ngIf="showZeroFieldsError" class="row">
                    <div class="col-xs-12 error-block">
                        <span class="error-block">{{ 'TL_ONE_OF_THE_FIELDS_MANDATORY' | translate }}</span>
                    </div>
                </div>
                <div>
                    <label class="search-title pb-20">{{'SEARCH'|translate}}</label>
                    <div class="card inner-card">
                        <label class="pb-20 fw-bold">{{ 'SOME_MANDATORY_FIELD' | translate }}</label>
                        <div class="col-md-12 d-flex">
                            <div class="col-md-6">
                                <div class="input-group">
                                    <div class="form-floating user-input p-bottom-24">
                                        <input type="email" name="emailAddress" formControlName="emailAddress"
                                            class="form-control" maxlength="50"
                                            placeholder="{{'NOTIFICATION_EMAIL_PRIMARY_RECIPIENT' | translate}}">
                                        <label for="email">{{'NOTIFICATION_EMAIL_PRIMARY_RECIPIENT' |
                                            translate}}</label>
                                    </div>
                                    <div *ngIf="notificationLogSearchForm.get('emailAddress')?.dirty && 
                                    notificationLogSearchForm.get('emailAddress')?.invalid"><span
                                            class="error-block">{{
                                            'EMAIL_REQUIRED' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group">
                                    <div class="form-floating user-input-height billing-width p-bottom-24">
                                        <select class="form-select" name="accessdrp" id="accessdrp" #notification
                                            (change)="changeNotificationTypeValue(notification.value)">
                                            <option *ngFor="let notiType of notificationData"
                                                [value]="[notiType.templateID, notiType.templateTypeName]">
                                                <a>{{notiType.templateTypeName}}</a>
                                            </option>
                                        </select>
                                        <label for="accessdrp">{{'NOTIFICATION_TYPE_TEXT' | translate }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex">
                            <div class="col-md-6 d-flex align-items-start">
                                <div class="col-md-4 mt-20">
                                    <label for="notificationSentDate">
                                        {{'NOTIFICATION_SENT_DATE_TEXT' | translate }}*</label>
                                </div>
                                <div class="col-md-8 d-flex align-items-center">
                                    <div class="col-md-6">
                                        <div class="input-group">
                                            <div class="form-floating  w-65">
                                                <input class="form-control" formControlName="dateFrom" name="dateFrom"
                                                    [(ngModel)]="model" ngbDatepicker #a="ngbDatepicker"
                                                    [maxDate]="maxDate" [minDate]="minDate" [autoClose]="true" readonly
                                                    [footerTemplate]='fromDateTemplate'
                                                    placeholder="{{'TL_FROM_LABEL' | translate}}">
                                                <label for="dateFrom">{{'TL_FROM_LABEL' | translate}}</label>
                                            </div>
                                            &nbsp;
                                            <img class="icon icon-cart-datepicker"
                                                src="../../../../assets/images/icon-date.svg" alt="calendar icon"
                                                (click)="a.toggle()" />
                                        </div>
                                        <ng-template #fromDateTemplate>
                                            <div class="btn-group float-start mt-2" role="group">
                                                <button class="btn btn-primary btn-sm"
                                                    (click)="model = today; a.close()">Today</button>
                                                <button class="btn btn-primary cta-blue info-btn btn-sm" (click)="model = undefined">{{
                                                    'CLEAR_TEXT'
                                                    |
                                                    translate
                                                    }}</button>
                                            </div>
                                            <button class="btn btn-primary btn-sm float-end mt-2" (click)="a.close()">{{
                                                'EFT_GUIDELINES_CLOSE' | translate
                                                }}</button>
                                        </ng-template>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-group">
                                            <div class="form-floating  w-65">
                                                <input class="form-control" formControlName="dateTo" name="dateTo"
                                                    [(ngModel)]="modelTo" ngbDatepicker #b="ngbDatepicker"
                                                    [maxDate]="maxDate" [minDate]="minDate" [autoClose]="true" readonly
                                                    [footerTemplate]='t' placeholder="{{'TL_TO_LABEL' | translate}}">
                                                <label for="dateFrom">{{'TL_TO_LABEL' | translate}}</label>
                                            </div>
                                            &nbsp;
                                            <img class="icon icon-cart-datepicker"
                                                src="../../../../assets/images/icon-date.svg" alt="calendar icon"
                                                (click)="b.toggle()">
                                        </div>
                                        <ng-template #t>
                                            <div class="btn-group float-start mt-2" role="group">
                                                <button class="btn btn-primary btn-sm"
                                                    (click)="modelTo = today; b.close()">Today</button>
                                                <button class="btn btn-primary cta-blue info-btn btn-sm"
                                                    (click)="modelTo = undefined">{{
                                                    'CLEAR_TEXT' |
                                                    translate
                                                    }}</button>
                                            </div>
                                            <button class="btn btn-primary btn-sm float-end mt-2" (click)="b.close()">{{
                                                'EFT_GUIDELINES_CLOSE' | translate
                                                }}</button>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group">
                                    <div class="form-floating user-input p-bottom-24">
                                        <input name="notificationKeyword" formControlName="notificationKeyword"
                                            type="text" class="form-control" maxlength="100"
                                            placeholder="{{'KEYWORD_IN_EMAIL_BODY_TEXT' | translate}}">
                                        <label for="notificationKeyword">{{'KEYWORD_IN_EMAIL_BODY_TEXT' |
                                            translate}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex">
                            <div class="col-md-6"></div>
                            <div class="col-md-6 d-flex justify-content-end" style="width: 47%;">
                                <button type="submit" class="btn btn-primary form-control primary-btn"
                                    style="margin-right:10px;" (click)="performSearch()">{{
                                    'SEARCH_KEY' | translate }}</button>
                                <button (click)="resetForm()" class="btn btn-primary cta-blue info-btn">{{
                                    'RESET_KEY' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <br />
        <div *ngIf="isSuccessfulSearch">
            <form [formGroup]="notificationLogArrayForm" class="form-horizontal register-form" novalidate>
                <div formArrayName="notificationLogArrayDetails">
                    <div class="col-md-12 d-flex align-items-center" style="margin-bottom: 10px;">
                        <div class="col-md-2" style="width:12%">{{ 'NOTIFICATION_TYPE_TEXT' | translate }} :</div>
                        <div class="col-md-2" style="width:15%"><strong>{{ notificationTypeDefaultValue |
                                translate}}</strong></div>
                        <div class="col-md-2" style="width:15%">
                            <div class="input-group">
                                <div class="form-floating user-input-height billing-width p-bottom-24">
                                    <select class="form-select" name="accessdrp" id="accessdrp" #notification
                                        (change)="filterNotificationTypeValue(notification.value)">
                                        <option *ngFor="let notificationType of notificationTypes"
                                            [value]="notificationType.name">
                                            <a>{{notificationType.name}}</a>
                                        </option>
                                    </select>
                                    <label for="accessdrp">{{'NOTIFICATION_STATUS' | translate }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" style="width: 58%">
                            <button type="button" class="btn btn-primary form-control primary-btn text-right"
                                (click)="resendNotificationMail()">
                                {{'RESEND_TEXT' | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="showEmptyCheckBoxError"><span class="error-block">{{ 'EMPTY_CHECKBOX' |
                                translate }}</span></div>
                        <div *ngIf="showInvalidEmailAddress"><span class="error-block">{{ 'EMAIL_VALID' |
                                translate }}</span></div>
                        <div *ngIf="showInvalidEmailAddressCount"><span class="error-block">{{
                                'NOTIFICATION_TOOLTIP_TEXT' |
                                translate }}</span></div>
                    </div>
                    <table class="table table-striped table-scrollable">
                        <thead>
                            <tr>
                                <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col">
                                    <input type="checkbox" (change)="markAllNotificationsSelection($event)">
                                </th>
                                <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col">
                                    {{'NOTIFICATION_SEARCH_ORIG_ADD_TEXT' |
                                    translate }}</th>
                                <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col">
                                    {{'NOTIFICATION_SEARCH_NEW_ADD_TEXT' |
                                    translate }}<img class="icon" src="assets/images/icon-tooltip.svg"
                                        ngbTooltip="{{'NOTIFICATION_TOOLTIP_TEXT' | translate}}"></th>
                                <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col">
                                    {{'NOTIFICATION_SEARCH_EMAIL_PARAMETER_BODY' |
                                    translate
                                    }}</th>
                                <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col">
                                    {{'NOTIFICATION_STATUS' |
                                    translate
                                    }}
                                </th>
                                <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col"
                                    sortable="createDateTime" (sort)="onSort($event)"><a
                                        href="javascript:void(0)" class="notification_a">{{'NOTIFICATION_CREATED_DATE_TIME'
                                        | translate
                                        }} </a>
                                </th>

                                <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col"
                                    sortable="sentDateTime" (sort)="onSort($event)"><a class="notification_a"
                                        href="javascript:void(0)">{{'NOTIFICATION_SENT_DATE_TIME' |
                                        translate
                                        }}</a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let notificationEntry of notificationEditFormGroup.controls; index as i"
                                [formGroupName]="i">
                                <td>
                                    <input type="checkbox" formControlName="isSelected"
                                        (change)="markNotificationItemSelection(i,$event)">
                                </td>
                                <td>{{notifications[i].recipientEmailAddress}}</td>
                                <td>
                                    <textarea id="data" rows="5" cols="20" formControlName="recipientEmailAddresses"
                                        maxlength="250" maxlength="250"></textarea>
                                    <div
                                        *ngIf="notificationEditFormGroup.controls[i].get('recipientEmailAddresses')?.invalid && notificationEditFormGroup.controls[i].get('recipientEmailAddresses')?.dirty">
                                        <span class="error-block">{{'EMAIL_VALID' | translate }}</span>
                                    </div>
                                </td>
                                <td>
                                    {{notifications[i].parameter}}</td>
                                <td class="align-right">
                                    {{notifications[i].status}}</td>
                                <td class="invoice-createDateTimeCell align-left td-date">
                                    {{ convertStringDateTime(notifications[i].createDateTime) | date:'yyyy-MM-dd
                                    HH:mm:ss'}}
                                </td>
                                <td class="invoice-sentDateTimeCell align-left td-date">
                                    {{ convertStringDateTime(notifications[i].sentDateTime) | date:'yyyy-MM-dd
                                    HH:mm:ss'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    </div>
</div>
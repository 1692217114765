import { CurrencyPipe, LowerCasePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, tap, throwError } from 'rxjs';
import {
  NgbdSortableHeader,
  SortEvent,
} from 'src/app/common/bootstrap-sortable-header/sortable-header.directive';
import { AdminTransactionLogService } from 'src/app/services/admin-transaction-log/admin-transaction-log.services';
import { LocalStorageService } from 'src/app/services/global/local-storage.service';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
const now = new Date();

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-obpp-transaction-log',
  templateUrl: './obpp-transaction-log.component.html',
  styleUrls: ['./obpp-transaction-log.component.css'],
})
export class ObppTransactionLogComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers?: QueryList<NgbdSortableHeader>;
  transactionLogSearchForm: FormGroup;
  transactions: any = [];
  transactionsAll: any = [];
  $transactionLogMetaData;
  minDate = {
    year: now.getFullYear() - 1,
    month: now.getMonth() + 1,
    day: now.getDate(),
  };
  maxDate = {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
  };
  minToDate = {
    year: now.getFullYear() - 1,
    month: now.getMonth() + 1,
    day: now.getDate(),
  };
  maxToDate = {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
  };
  today = {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
  };
  cleardate = { year: '', month: '', day: '' };
  model: any;
  modelTo: any;
  changeCreditTypeDefaultValue: string = 'ALL';
  paymentTypeDefaultValue: string = 'ALL';
  paymentStatusDefaultValue: string = 'ALL';
  createdMethodeDefaultValue: string = 'ALL';
  sendToSAPDefaultValue: string = 'ALL';
  paymentStatusOptions = [
    { label: 'All', value: 'all' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'System Error', value: 'SystemError' },
    { label: 'No Response', value: 'NoResponse' },
  ];
  createMethodOptions = ['All', 'PayNow', 'QuickPay-C', 'AutoPay'];
  sentToSAPOptions = ['All', 'SUCCESS', 'FAIL'];
  isSuccessfulSearch = false;
  showZeroResultsMessage = false;
  showZeroFieldsError = false;
  CURRENT_VALUE = -1;
  HISTORY_VALUE = -1;
  currentTransictionsBatch: any = [];
  currentPage: number = 0;
  totalPages: number = 0;
  rowsPerPage: number = 15;
  currentFirstEntry: number = 1;
  currentLastEntry: number = 15;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private localStorageService: LocalStorageService,
    public translate: TranslateService,
    private dataSharingService: DataSharingService,
    private adminTransLogService: AdminTransactionLogService,
    private obbpLoginService: OBPPLoginService,
    private currencyPipe: CurrencyPipe
  ) {
    this.transactionLogSearchForm = this.fb.group({
      accountNumber: [
        '',
        [
          Validators.pattern('^[a-zA-Z0-9]{2,8}((,[a-zA-Z0-9]{2,8})*)$'),
          Validators.maxLength(50),
        ],
      ],
      name: ['', [Validators.maxLength(50)]],
      cardHolderName: [
        '',
        [
          Validators.pattern("^[a-zA-Z0-9.'\\-_\\s]*$"),
          Validators.maxLength(50),
        ],
      ],
      referenceNumber: [
        '',
        [
          Validators.pattern('^[a-zA-Z0-9]+$'),
          Validators.minLength(6),
          Validators.maxLength(6),
        ],
      ],
      payerName: ['', [Validators.maxLength(25)]],
      invoiceNumber: [
        '',
        [
          Validators.pattern('^[0-9]{1,12}((,[0-9]{1,12})*)$'),
          Validators.maxLength(50),
        ],
      ],
      shipmentPin: ['', [Validators.pattern('^[a-zA-Z0-9]*$')]],
      searchDuration: ['CURRENT'],
      dateFrom: [''],
      dateTo: [''],
      amountFrom: [''],
      amountTo: [''],
      creditCardType: [''],
    });
    this.$transactionLogMetaData = this.adminTransLogService
      .getTransactionLogMetaData(obbpLoginService.x_csrf_token)
      .pipe(
        map((data) => {
          if (
            data &&
            data.serviceResponse &&
            data.serviceResponse.type &&
            data.serviceResponse.type === 'success' &&
            data.serviceResponse.object
          ) {
            let currentMonths = parseInt(data.serviceResponse.object.CURRENT);
            this.HISTORY_VALUE = parseInt(data.serviceResponse.object.HISTORY);
            this.CURRENT_VALUE = currentMonths;
            this.changeDates({ target: { value: 'CURRENT' } });
            return data.serviceResponse.object;
          } else {
            console.error('not data available');
          }
        }),
        catchError((err) => {
          console.log(err);
          return err;
        })
      );
  }

  ngOnInit(): void {}
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers?.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '' || column === '') {
      this.transactions = this.transactions;
    } else {
      this.transactions = [...this.transactions].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }
  objectToArray(object: any) {
    return Object.keys(object);
  }

  isNotEmpty(value: any) {
    return (
      this.transactionLogSearchForm.get(value)?.value &&
      typeof this.transactionLogSearchForm.get(value)?.value === 'string' &&
      this.transactionLogSearchForm.get(value)?.value.replace(/\s/g, '') != ''
    );
  }

  performSearch() {
    let isValid = this.transactionLogSearchForm?.valid;
    if (isValid) {
      if (
        !(
          this.isNotEmpty('accountNumber') ||
          this.isNotEmpty('invoiceNumber') ||
          this.isNotEmpty('amountFrom') ||
          this.isNotEmpty('amountTo') ||
          this.isNotEmpty('shipmentPin') ||
          this.transactionLogSearchForm.get('dateFrom')?.value ||
          this.transactionLogSearchForm.get('dateTo')?.value
        )
      ) {
        this.showZeroFieldsError = true;
        return;
      }
      this.showZeroFieldsError = false;
      let searchData = {
        creditCardType: this.transactionLogSearchForm.get('creditCardType')
          ?.value
          ? this.transactionLogSearchForm.get('creditCardType')?.value
          : this.changeCreditTypeDefaultValue,
        duration: this.transactionLogSearchForm.get('searchDuration')?.value,
        accountNumber:
          this.transactionLogSearchForm.get('accountNumber')?.value,
        accountName: this.transactionLogSearchForm.get('name')?.value,
        paymentType: this.transactionLogSearchForm.get('creditCardType')?.value
          ? this.transactionLogSearchForm.get('creditCardType')?.value
          : this.changeCreditTypeDefaultValue,
        cardHolderName:
          this.transactionLogSearchForm.get('cardHolderName')?.value,
        referenceNumber:
          this.transactionLogSearchForm.get('referenceNumber')?.value,
        payerName: this.transactionLogSearchForm.get('payerName')?.value,
        invoiceNumber:
          this.transactionLogSearchForm.get('invoiceNumber')?.value,
        shipmentPin: this.transactionLogSearchForm.get('shipmentPin')?.value,
        amountFrom: this.extractActualAmountValue(
          this.transactionLogSearchForm.get('amountFrom')?.value
        ),
        amountTo: this.extractActualAmountValue(
          this.transactionLogSearchForm.get('amountTo')?.value
        ),
        dateFromCal: this.formatDateToDateTime(
          this.transactionLogSearchForm.get('dateFrom')?.value
        ),
        dateToCal: this.formatDateToDateTime(
          this.transactionLogSearchForm.get('dateTo')?.value
        ),
        dateFrom: this.formatDateToDateZero(
          this.transactionLogSearchForm.get('dateFrom')?.value
        ),
        dateTo: this.formatDateToDateZero(
          this.transactionLogSearchForm.get('dateTo')?.value
        ),
        userRole: 'Administrator',
      };
      this.adminTransLogService
        .submitTransactionLogData(
          searchData,
          this.obbpLoginService.x_csrf_token
        )
        .subscribe(
          (data: any) => {
            if (
              data &&
              data.serviceResponse &&
              data.serviceResponse.type === 'success'
            ) {
              this.transactions = data.serviceResponse.object;
              this.transactionsAll = data.serviceResponse.object;
              this.isSuccessfulSearch = true;
              this.showZeroResultsMessage = false;
              this.totalPages = Math.ceil(
                this.transactions.length / this.rowsPerPage
              );
              console.log('totalPages -s', this.totalPages);
              this.currentPage = 0;
              this.currentTransictionsBatch =
                this.totalPages !== 0
                  ? this.transactions.slice(0, this.rowsPerPage)
                  : this.transactions;
              this.currentFirstEntry = 1;
              this.currentLastEntry = 15;
              return data.serviceResponse.object;
            } else if (
              data &&
              data.serviceResponse &&
              data.serviceResponse.type === 'error' &&
              data.serviceResponse.message === 'NO_TRANSACTIONS_FOUND'
            ) {
              this.transactions = [];
              this.transactionsAll = [];
              this.isSuccessfulSearch = false;
              this.showZeroResultsMessage = true;
            } else {
              this.showZeroResultsMessage = true;
            }
          },
          (error: any) => {
            console.error(error);
            this.isSuccessfulSearch = false;
          }
        );
      this.paymentTypeDefaultValue = 'All';
      this.paymentStatusDefaultValue = 'Approved';
      this.createdMethodeDefaultValue = 'All';
      this.sendToSAPDefaultValue = 'All';
    }
  }

  formatMoney(value: string) {
    const temp = `${value}`.replace(/\,/g, '');
    return this.currencyPipe.transform(temp)?.replace('$', '');
  }

  formatDateToDateTime(value: any) {
    if (value) {
      return (
        value.year +
        '-' +
        this.evalSingleDigit(value.month) +
        '-' +
        this.evalSingleDigit(value.day) +
        'T04:00:00.000Z'
      );
    }
    return value;
  }

  formatDateToDateZero(value: any) {
    if (value) {
      return (
        value.year +
        '' +
        this.evalSingleDigit(value.month) +
        '' +
        this.evalSingleDigit(value.day) +
        '000000'
      );
    }
    return value;
  }

  evalSingleDigit(value: any) {
    return value < 10 ? '0' + value : value;
  }

  extractActualAmountValue(value: string) {
    if (value) {
      return value.replace(',', '');
    }
    return value;
  }

  transformTotal(event: any) {
    const value = this.transactionLogSearchForm.get(event.target.name)?.value;
    this.transactionLogSearchForm
      .get(event.target.name)
      ?.setValue(this.formatMoney(value.replace(/\,/g, '')), {
        emitEvent: false,
      });
  }

  changeCreditTypeValue(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.changeCreditTypeDefaultValue = filterValue;
    this.transactionLogSearchForm.get('creditCardType')?.setValue(filterValue);
  }

  filterTransictions({
    event,
    type,
    filterInput,
  }: {
    event?: Event;
    type: string;
    filterInput?: string;
  }) {
    const filterValue = event
      ? (event.target as HTMLInputElement).value
      : filterInput || '';
    switch (type) {
      case 'paymentType':
        this.paymentTypeDefaultValue =
          filterValue.toLowerCase() === 'mastercard' ? 'master' : filterValue;
        break;
      case 'paymentStatus':
        this.paymentStatusDefaultValue = filterValue;
        break;
      case 'createMethod':
        this.createdMethodeDefaultValue = filterValue;
        break;
      case 'sendToSAP':
        this.sendToSAPDefaultValue = filterValue;
        break;
    }

    if (
      this.transactionsAll &&
      this.transactionsAll.length > 0 &&
      filterValue
    ) {
      const filteredPaymentStatus =
        this.paymentStatusDefaultValue.toLowerCase() === 'all'
          ? this.transactionsAll
          : this.transactionsAll.filter((element: any) => {
              return (
                element.paymentStatus.toLowerCase() ===
                this.paymentStatusDefaultValue.toLowerCase()
              );
            });

      const filteredByPaymentType =
        this.paymentTypeDefaultValue.toLowerCase() === 'all'
          ? filteredPaymentStatus
          : filteredPaymentStatus.filter((element: any) => {
              return (
                element.paymentType &&
                element.paymentType.toLowerCase() ===
                  this.paymentTypeDefaultValue.toLowerCase()
              );
            });

      const filteredByCreateMethod =
        this.createdMethodeDefaultValue.toLowerCase() === 'all'
          ? filteredByPaymentType
          : filteredByPaymentType.filter((element: any) => {
              return (
                element.createMethod &&
                element.createMethod.toLowerCase() ===
                  this.createdMethodeDefaultValue.toLowerCase()
              );
            });

      const filteredBySendToSAP =
        this.sendToSAPDefaultValue.toLowerCase() === 'all'
          ? filteredByCreateMethod
          : filteredByCreateMethod.filter((element: any) => {
              return (
                element.sentToSAP &&
                element.sentToSAP.toLowerCase() ===
                  this.sendToSAPDefaultValue.toLowerCase()
              );
            });

      this.transactions = filteredBySendToSAP;
      this.totalPages = Math.ceil(this.transactions.length / this.rowsPerPage);
      this.currentPage = 0;
      this.currentTransictionsBatch =
        this.totalPages !== 0
          ? this.transactions.slice(0, this.rowsPerPage)
          : this.transactions;
      this.currentFirstEntry = 1;
      this.currentLastEntry = 15;
    }
  }

  nextPage(): void {
    this.currentPage = this.currentPage + 1;
    this.currentFirstEntry = this.currentPage * this.rowsPerPage + 1;
    this.currentLastEntry =
      (this.currentPage + 1) * this.rowsPerPage > this.transactions.length
        ? this.transactions.length
        : (this.currentPage + 1) * this.rowsPerPage;
    this.currentTransictionsBatch = this.transactions.slice(
      this.currentPage * this.rowsPerPage,
      (this.currentPage + 1) * this.rowsPerPage
    );
  }

  previousPage(): void {
    this.currentPage = this.currentPage - 1;
    this.currentFirstEntry =
      this.currentPage * this.rowsPerPage < 1
        ? 1
        : this.currentPage * this.rowsPerPage + 1;
    this.currentLastEntry = (this.currentPage + 1) * this.rowsPerPage;
    this.currentTransictionsBatch = this.transactions.slice(
      this.currentPage * this.rowsPerPage,
      (this.currentPage + 1) * this.rowsPerPage
    );
  }

  jumpToPage(page: number): void {
    this.currentPage = page;
    this.currentFirstEntry =
      this.currentPage * this.rowsPerPage < 1
        ? 1
        : this.currentPage * this.rowsPerPage + 1;
    this.currentLastEntry =
      (this.currentPage + 1) * this.rowsPerPage > this.transactions.length
        ? this.transactions.length
        : (this.currentPage + 1) * this.rowsPerPage;
    this.currentTransictionsBatch = this.transactions.slice(
      this.currentPage * this.rowsPerPage,
      (this.currentPage + 1) * this.rowsPerPage
    );
  }

  resetForm() {
    this.transactionLogSearchForm.reset();
    this.transactionLogSearchForm.get('searchDuration')?.setValue('CURRENT');
    this.showZeroResultsMessage = false;
    this.showZeroFieldsError = false;
    this.transactions = [];
    this.transactionsAll = [];
    this.changeCreditTypeDefaultValue = 'ALL';
    this.isSuccessfulSearch = false;
  }

  changeDates(event: any) {
    let value = event.target.value;
    const current = new Date();
    if (value == 'CURRENT') {
      current.setMonth(current.getMonth() - this.CURRENT_VALUE);
    } else {
      current.setMonth(current.getMonth() - this.HISTORY_VALUE);
    }
    this.minDate.year = current.getFullYear();
    this.minDate.day = current.getDate();
    this.minDate.month = current.getMonth() + 1;
    this.minToDate.year = current.getFullYear();
    this.minToDate.day = current.getDate();
    this.minToDate.month = current.getMonth() + 1;
  }

  exportedToSheet() {
    console.log('sheet', this.transactions);
    const exportedSheet = this.transactions.map((transaction: any) => {
      return {
        'Account #': transaction.accountNumber,
        'Invoice #': transaction.invoiceNumber,
        'Invoice Payment Amount': transaction.invoiceAmount,
        'Invoice Date': transaction.invoiceDateTime,
        'Payment Date': transaction.paymentDateTime,
        'Total Payment Amount': transaction.paymentAmount,
        'Payment Type': transaction.paymentType,
        'CC#': transaction.ccLastDigits,
        'Shipment PIN/Amount': transaction.transactionLogDetailsDTOList
          ? transaction.transactionLogDetailsDTOList
              .map((t: any) => `${t.pinNumber}-${t.pinAmountPaid}$`)
              .join('  ')
          : null,
        'Payer/Cardholder': transaction.payerName,
        'Ref #': transaction.referenceNumber,
        'Email Sent': transaction.emailSend,
        'Account Name': transaction.accountName,
        Type: transaction.createMethod,
        'User ID': transaction.userid,
        'Payment ID': transaction.paymentID,
        'Sent To SAP': transaction.sentToSAP,
        'B/L': transaction.shipmentPin,
        'Payment Transaction ID': transaction.paymentTransactionID,
        'Payment Status': transaction.paymentStatus,
      };
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportedSheet);
    console.log;
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      'Tranaction_log' + '_export_' + new Date().toISOString() + EXCEL_EXTENSION
    );
  }
}

<div class="row">
    <h2 class="col-md-12">{{'WELCOME_BILLING' | translate}}</h2>
    <!-- Separator -->
    <div class="col-md-12">
        <hr />
    </div>
    <!-- End Separator -->
    <p class="col-md-12">{{'REGISTER_MSG' | translate}}</p>
    <br />
    <p class="col-md-12">{{'CREATE_PASS' | translate}}</p>
</div>

<div *ngIf="!successSetPassword">

    <form [formGroup]="ActiveForm">

        <div class="row p-bottom-10" *ngIf="errMsgList.length > 0">
            <div class="col-md-12 error-block error-pageLevel">
                <ul>
                    <li *ngFor="let errMsg of errMsgList">
                        {{errMsg.value | translate}}</li>
                </ul>
            </div>
        </div>


        <div class="form-group col-md-12 row p-top-10">
            <div class="col-md-2">
                <label for="email" class="control-label">{{'USER_ID' | translate}}</label>
            </div>
            <div class="col-md-5">
                <span name="email" class="control-label">{{emailAddress}}</span>
            </div>
        </div>



        <!-- Begin Current Password -->
        <div class="form-group col-md-12 row">
            <div class="col-md-2">
                <label for="password" class="control-label">{{'PASSWORD' | translate}}
                    <br />
                    <small>{{'PASSWORD_MSG' | translate}}</small>
                </label>
            </div>
            <div class="col-md-5">
                <div class="form-group input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text fa-icon-field" id="basic-addon1">
                            <i class="fa fa-key"></i>
                        </span>
                    </div>
                    <input type="password" class="form-control" formControlName="password" minlength="8" maxLength="16"
                        aria-label="Password" aria-describedby="basic-addon1">
                    <div
                        *ngIf="ActiveForm.get('password')?.invalid && (ActiveForm.get('password')?.dirty || submitted)">
                        <span class="error-block">{{'PASSWORD_ERR' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Current Password -->

        <!-- Begin Confirm Password -->
        <div class="form-group col-md-12 row">
            <div class="col-md-2">
                <label for="confirmpassword" class="control-label">{{'PASSWORD_CONFIRM' | translate}}

                </label>
            </div>
            <div class="col-md-5">
                <div class="form-group input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text fa-icon-field" id="basic-addon1">
                            <i class="fa fa-key"></i>
                        </span>
                    </div>
                    <input type="password" class="form-control" formControlName="confirmpassword" minlength="8"
                        maxLength="16" aria-label="Password" aria-describedby="basic-addon1">
                    <div
                        *ngIf="ActiveForm.get('confirmpassword')?.invalid && (ActiveForm.get('confirmpassword')?.dirty || submitted)">
                        <span class="error-block">{{'PASSWORD_ERR' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Confirm Password -->

        <div class="form-group col-md-12 row" *ngIf="pwdMisMatch">

            <div class="col-md-7">
                <span class="error-block">{{'NEW_AND_CONFIRM_PASSWORD_MISMATCH' | translate}}</span>
            </div>

        </div>

        <div class="form-group">
            <div class="col-md-4">
                <button type="submit" (click)="setPassword($event)" class="btn btn-primary btn-block">{{'SAVE_PASSWORD'
                    | translate}}</button>
            </div>
        </div>
    </form>
</div>



<div *ngIf="successSetPassword">
    <div class="modal-header">
        <h4 class="modal-title">{{'SET_PASSWORD_HEADER_KEY' | translate}}</h4>
    </div>

    <div class="modal-body text-center">{{'SET_PASSWORD_BODY_KEY' | translate}}
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button class="btn btn-primary" (click)="navigateToLoginPage()">{{'OK' | translate}}</button>
    </div>
</div>
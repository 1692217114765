import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalDataConfiguration } from 'src/app/data-config/global-data-config';
import { EnrollAutopayService } from 'src/app/services/enroll-autopay/enroll-autopay.service';
import {
  AutoPayUserAccount,
  EnrollAutoPay,
} from 'src/app/models/enrollAutopay';
import { OBPPModalDialogComponent } from '../../error-component/modal-dialog/modal-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalUserLoginComponent } from 'src/app/common/shared/pop-up-modal/modal-user-login/modal-user-login.component';
import { OBPPQuickPayCPWAService } from 'src/app/services/cpwa-services/quickpay-cpwa.services';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';

@Component({
  selector: 'app-obpp-enroll-autopay',
  templateUrl: './obpp-enroll-autopay.component.html',
  styleUrls: ['./obpp-enroll-autopay.component.css'],
})
export class ObppEnrollAutopayComponent implements OnInit {
  @ViewChild('init__payment') init__payment!: ElementRef;
  paymentSuccessFlag: boolean = false;
  serviceData: any = new Map();
  showRegisterOption = false;
  globalDataConfiguration = new GlobalDataConfiguration();
  enrollAutoPayForm: FormGroup;
  phoneNumberMask = [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  isEmailMisMatch = false;
  showNextPage = false;
  errorMap = new Map();
  isLoginModelShown = false;
  paymentInformation: any = [];
  declinedMessage: boolean = false;
  cpwaPayload: any;
  paymentInfo: string = ''; // will be deleted
  paymentURL = '';
  frameHeight = 624;
  cpwaQuickPayData: any;
  auotpayEncData: any;
  enrollAutoPaySubmited: any;
  constructor(
    private dataSharingService: DataSharingService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private enrollAutoPayService: EnrollAutopayService,
    private translateServeice: TranslateService,
    private obppQuickPayCPWAService: OBPPQuickPayCPWAService,
    private obppLoginService: OBPPLoginService,
    private route: ActivatedRoute
  ) {
    this.paymentURL = this.obppLoginService.getCPWAPaymentURL();
    this.enrollAutoPayForm = this.fb.group({
      emailAddress: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      confirmEmailAddress: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      phoneNumberExt: ['', Validators.pattern('^[0-9]{0,6}$')],
      companyName: ['', Validators.required],
      registerCustomer: [true],
      accountDetails: this.fb.array(
        [this.createEmpFormGroup()],
        [Validators.required, Validators.minLength(1), Validators.maxLength(5)]
      ),
    });
  }

  createEmpFormGroup() {
    return this.fb.group({
      accountNumber: [
        '',
        [
          Validators.minLength(2),
          Validators.maxLength(12),
          Validators.required,
        ],
      ],
      postalCode: [
        '',
        [Validators.minLength(4), Validators.maxLength(7), Validators.required],
      ],
      expectedPostalCode: '',
      paymentDate: [''],
      autoPayFlag: [false, [Validators.required]],
      emailNotificationId: [
        '',
        [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
    });
  }
  get accountDetails(): FormArray {
    return this.enrollAutoPayForm.get('accountDetails') as FormArray;
  }

  checkEmail(event: any) {
    let email = this.enrollAutoPayForm.get('emailAddress')?.value;
    let isEmailValid = this.enrollAutoPayForm.get('emailAddress')?.valid;
    this.checkMatching('check');
    if (isEmailValid === true) {
      this.enrollAutoPayService.checkEmail(email).subscribe(
        (data: any) => {
          if (
            data &&
            data.serviceResponse &&
            data.serviceResponse.type &&
            data.serviceResponse.type === 'success'
          ) {
            if (
              data.serviceResponse.object === null ||
              data.serviceResponse.object === undefined
            ) {
              this.showRegisterOption = true;
              return;
            } else {
              this.showLoginModel(email);
            }
          }
          this.showRegisterOption = false;
        },
        (error: any) => {
          console.error(error);
          this.showRegisterOption = false;
        }
      );
    }
  }

  checkMatching(event: any) {
    let email = this.enrollAutoPayForm.get('emailAddress')?.value;
    let dirtyEmail = this.enrollAutoPayForm.get('emailAddress')?.dirty;
    let cemail = this.enrollAutoPayForm.get('confirmEmailAddress')?.value;
    let dirtyCEmail = this.enrollAutoPayForm.get('confirmEmailAddress')?.dirty;
    if (email && dirtyEmail) {
      if (cemail && dirtyCEmail) {
        if (cemail != email) {
          this.isEmailMisMatch = true;
        } else {
          this.isEmailMisMatch = false;
        }
      }
    }
  }

  checkAccountNumber(index: any) {
    let isValid =
      this.accountDetails.controls[index].get('accountNumber')?.valid;
    let accountNumber =
      this.accountDetails.controls[index].get('accountNumber')?.value;
    if (
      isValid === true &&
      this._checkIfAccountNumberExists(accountNumber) === false
    ) {
      this.errorMap.delete(index);
      this.enrollAutoPayService.checkAccountNumber(accountNumber).subscribe(
        (data: any) => {
          if (
            data &&
            data.serviceResponse &&
            data.serviceResponse.type &&
            data.serviceResponse.type === 'success'
          ) {
            if (
              data.serviceResponse.object &&
              data.serviceResponse.object.accountNumber &&
              data.serviceResponse.object.scheduledNextInvoiceDate
            ) {
              let dateString =
                data.serviceResponse.object.scheduledNextInvoiceDate;
              var newString =
                dateString.substr(4, 2) +
                '-' +
                dateString.substr(6, 2) +
                '-' +
                dateString.substr(0, 4);
              this.accountDetails.controls[index]
                .get('paymentDate')
                ?.setValue(newString);
              this.accountDetails.controls[index]
                .get('expectedPostalCode')
                ?.setValue(data.serviceResponse.object.accountBillingPostcode);
              this.enrollAutoPayService.fetchAccountId(accountNumber).subscribe(
                (accountData: any) => {
                  if (
                    accountData &&
                    accountData.serviceResponse &&
                    accountData.serviceResponse.type &&
                    accountData.serviceResponse.type === 'success'
                  ) {
                    data.serviceResponse.object.accountID =
                      accountData.serviceResponse.object;
                    this.serviceData.set(
                      accountNumber,
                      data.serviceResponse.object
                    );
                  }
                },
                (error: any) => {
                  console.error(error);
                }
              );

              this.enrollAutoPayService
                .fetchAccountStatus(accountNumber)
                .subscribe(
                  (accountData: any) => {
                    if (
                      accountData &&
                      accountData.serviceResponse &&
                      accountData.serviceResponse.type &&
                      accountData.serviceResponse.type === 'success'
                    ) {
                      data.serviceResponse.object.obppAutoPayAccountStatus =
                        accountData.serviceResponse.object.obppAutoPayAccountStatus;
                      data.serviceResponse.object.obppAutoPayAccountOwnerID =
                        accountData.serviceResponse.object.obppAutoPayAccountOwnerID;
                      this.serviceData.set(
                        accountNumber,
                        data.serviceResponse.object
                      );
                      if (
                        accountData.serviceResponse.object
                          .obppAutoPayAccountStatus === 'ENROLLED'
                      ) {
                        this.showAlreadyEnrolledModel();
                      }
                    }
                  },
                  (error: any) => {
                    console.error(error);
                  }
                );
              return;
            }
          }
        },
        (error: any) => {
          console.error(error);
        }
      );
    } else if (
      this._checkIfAccountNumberExists(accountNumber) === true &&
      this._checkNoDuplicateAccountsByIndex(index)
    ) {
      this.errorMap.set(index, 'duperror');
    } else if (isValid === true) {
      this.errorMap.delete(index);
    }
  }

  _checkIfAccountNumberExists(accountNumber: string): boolean {
    return this.serviceData.get(accountNumber) != (undefined || null);
  }

  checkPostalCode(index: any) {
    let isValid = this.accountDetails.controls[index].get('postalCode')?.valid;
    let postalCode =
      this.accountDetails.controls[index].get('postalCode')?.value;
    var expectedPostalCode =
      this.accountDetails.controls[index].get('expectedPostalCode')?.value;
    if (isValid === true) {
      if (
        expectedPostalCode === null ||
        expectedPostalCode === undefined ||
        expectedPostalCode === ''
      ) {
        this.checkAccountNumber(index);
      }
    }
  }

  register() {
    this.markAllDirty(this.enrollAutoPayForm);
    this.markAllArrayDirty(this.accountDetails);
    let isEnrollPayValid = this.enrollAutoPayForm?.valid;
    let isAccountDetailsValid = this.accountDetails?.valid;
    if (isEnrollPayValid === true && isAccountDetailsValid == true) {
      let isBusinessValid = true;
      let enrollAutoPay = new EnrollAutoPay();
      let accountNumbers: string[] = [];
      enrollAutoPay.firstName = this.enrollAutoPayForm.get('firstName')?.value;
      enrollAutoPay.lastName = this.enrollAutoPayForm.get('lastName')?.value;
      enrollAutoPay.phoneNumber =
        this.enrollAutoPayForm.get('phoneNumber')?.value;
      enrollAutoPay.phoneNumberExt =
        this.enrollAutoPayForm.get('phoneNumberExt')?.value;
      enrollAutoPay.companyName =
        this.enrollAutoPayForm.get('companyName')?.value;
      enrollAutoPay.emailAddress =
        this.enrollAutoPayForm.get('emailAddress')?.value;
      enrollAutoPay.confirmEmailAddress = this.enrollAutoPayForm.get(
        'confirmEmailAddress'
      )?.value;
      enrollAutoPay.userID = '';
      enrollAutoPay.appAndBcRegistrations =
        this.enrollAutoPayForm.get('registerCustomer')?.value &&
        this.showRegisterOption === true;

      if (enrollAutoPay.confirmEmailAddress != enrollAutoPay.emailAddress) {
        isBusinessValid = false;
      }
      if (isBusinessValid == true) {
        for (let index = 0; index < this.accountDetails.length; index++) {
          if (this.accountDetails.controls[index]?.valid === false) {
            break;
            isBusinessValid = false;
          } else if (
            this.accountDetails.controls[index].get('postalCode')?.value !=
            this.accountDetails.controls[index].get('expectedPostalCode')?.value
          ) {
            break;
            isBusinessValid = false;
          } else if (
            accountNumbers.includes(
              this.accountDetails.controls[index].get('accountNumber')?.value
            ) === true
          ) {
            break;
            isBusinessValid = false;
          } else {
            let autopayUserAcc = new AutoPayUserAccount();
            let serviceData: any = this.serviceData.get(
              this.accountDetails.controls[index].get('accountNumber')?.value
            );
            autopayUserAcc.accountID = serviceData.accountID;
            autopayUserAcc.creditcardid = '';
            autopayUserAcc.accountNumber = serviceData.accountNumber;
            autopayUserAcc.obppAutoPayAccountStatus =
              serviceData.obppAutoPayAccountStatus;
            autopayUserAcc.sendSuccessfullNotificationInd =
              this.accountDetails.controls[index].get('autoPayFlag')?.value;
            autopayUserAcc.carbonCopyEmailAddress =
              this.accountDetails.controls[index].get(
                'emailNotificationId'
              )?.value;
            autopayUserAcc.targetActionDate = serviceData.scheduledInvoiceDate;
            autopayUserAcc.targetActionDateFrontEnd =
              this._convertDateToFrontEnddate(
                serviceData.scheduledNextInvoiceDate
              ); // need to chekc this
            autopayUserAcc.postalCodeSAP = serviceData.accountBillingPostcode;
            autopayUserAcc.postalCode = serviceData.accountBillingPostcode;
            enrollAutoPay?.autoPayUserAccounts?.push(autopayUserAcc);
          }
        }
        if (isBusinessValid === true) {
          this.enrollAutoPayService.enrollAutoPay(enrollAutoPay).subscribe(
            (data: any) => {
              if (
                data &&
                data.serviceResponse &&
                data.serviceResponse.type &&
                data.serviceResponse.type == 'success' &&
                data.serviceResponse.object != null
              ) {
                this.enrollAutoPaySubmited = enrollAutoPay;
                let auotpayData: EnrollAutoPay = {
                  userId: data.serviceResponse.object,
                  autoPayAccountDtoList: [{ accountNumber: '12345' }],
                };
                this.enrollAutoPayService.setAutoPay(auotpayData).subscribe(
                  (enData: any) => {
                    this.showNextPage = true;
                    this.cpwaPayload = enData.serviceResponse.object;
                    this.cpwaQuickPayData = enData;
                    this.auotpayEncData = auotpayData;
                    this.paymentURL = this.obppLoginService.getCPWAPaymentURL();
                    this.dataSharingService.IsLoadingEnabled.next(false);
                    setTimeout(() => {
                      this.init__payment.nativeElement.submit();
                    }, 200);
                  },
                  (error) => {
                    console.log('Error');
                  }
                );
              }
            },
            (error) => {
              console.log('Error');
              this.dataSharingService.IsLoadingEnabled.next(false);
            }
          );
        }
      }
    }
  }
  markAllDirty(enrollAutoPayForm: FormGroup) {
    Object.keys(enrollAutoPayForm.controls).forEach((key) => {
      if (enrollAutoPayForm.get(key) instanceof FormArray) {
      } else {
        enrollAutoPayForm.get(key)?.markAsDirty();
      }
    });
  }

  markAllArrayDirty(enrollAutoPayForm: FormArray) {
    enrollAutoPayForm.controls.forEach((t) => {
      this.markAllDirty(<FormGroup>t);
    });
  }
  //scheduledNextInvoiceDate
  _convertDateToFrontEnddate(dateString: string): string {
    if (dateString === null || dateString === undefined || dateString === '')
      return '';
    return dateString.substring(0, 8);
  }

  showNextPageAction() {
    this.showNextPage = false;
  }

  remove(itemId: any) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = 'REM_ACCT_CONF_TEXT';
    modalRef.componentInstance.my_modal_content = 'REMOVE_ACCT_SURE_CONF_TEXT';
    modalRef.componentInstance.firstButtonLabel = 'REMOVE_ACCT_CONF_TEXT';
    modalRef.componentInstance.secondButtonLabel = 'CANCEL';
    modalRef.componentInstance.modalType = 'warning';

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      this.accountDetails.controls.splice(itemId, 1);
      this.errorMap.delete(itemId);
      if (this.accountDetails.controls.length == 0) {
        this.accountDetails.controls.push(this.createEmpFormGroup());
        this.accountDetails.updateValueAndValidity({ emitEvent: true });
      }
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {
      console.log($e);
    });
  }

  showAlreadyEnrolledModel() {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = 'ACCOUNT_EXISTS_TEXT';
    modalRef.componentInstance.my_modal_content = 'OLD_SYSTEM_TXT';
    modalRef.componentInstance.firstButtonLabel = 'EFT_GUIDELINES_CLOSE';
    modalRef.componentInstance.modalType = 'warning';
    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      console.log($e);
    });
  }

  showLoginModel(email: any) {
    if (this.isLoginModelShown == true) {
      return;
    }
    const modalRef = this.modalService.open(ModalUserLoginComponent);
    modalRef.componentInstance.my_modal_title = 'LOGIN_TO_YOUR_ACCOUNT_KEY';
    modalRef.componentInstance.my_modal_content = 'POPUP_LOGIN_TITLE_TEXT';
    modalRef.componentInstance.firstButtonLabel = 'LOGIN_TEXT';
    modalRef.componentInstance.secondButtonLabel =
      'CONTINUE_UNAUTHENTICATED_USER_TEXT';
    modalRef.componentInstance.modalType = 'warning';
    modalRef.componentInstance.username = email;

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      console.log($e);
    });
    this.isLoginModelShown = true;
  }

  addAccount() {
    let currentLen = this.accountDetails.controls.length;
    if (
      this.accountDetails.controls[currentLen - 1].get('accountNumber')
        ?.valid &&
      this._checkNoDuplicateAccounts(currentLen)
    ) {
      this.accountDetails.controls.push(this.createEmpFormGroup());
      this.accountDetails.updateValueAndValidity({ emitEvent: true });
    }
  }
  private _checkNoDuplicateAccounts(currentLen: any) {
    for (let i = 0; i < currentLen; i++) {
      for (let j = 0; j < currentLen && i != j; j++) {
        if (
          this.accountDetails.controls[i].get('accountNumber')?.value ===
          this.accountDetails.controls[j].get('accountNumber')?.value
        ) {
          return false;
        }
      }
    }
    return true;
  }

  private _checkNoDuplicateAccountsByIndex(i: any) {
    let currentLen = this.accountDetails.controls.length;
    for (let j = 0; j < currentLen && i != j; j++) {
      if (
        this.accountDetails.controls[i].get('accountNumber')?.value ===
        this.accountDetails.controls[j].get('accountNumber')?.value
      ) {
        return true;
      }
    }
    return false;
  }
  ngOnInit(): void {
    console.log('Enroll Autopay component');
  }

  openModal(
    title: string,
    errorMsg: string,
    firstButtonLabel: any,
    secondButtonLabel: any,
    modalType: string
  ) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      this.returnToManageUsers();
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {});
  }

  returnToManageUsers() {
    this.router.navigateByUrl('/home'), { relativeTo: this.route };
    this.dataSharingService.activeIdString.next('/home');
  }
  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {
    if (event.data.event === 'cpwaResize') {
      this.frameHeight = event.data.height;
    }

    if (event.data.event === 'cpwaCommit') {
      let passData = event.data;
      let payload;
      let property: keyof typeof event.data;
      for (property in event.data) {
        if (property === 'payload') {
          payload = event.data[property];
        }
      }

      let quickPaySumitpayment = {
        userId: this.auotpayEncData.userId,
        userName: this.enrollAutoPayForm.get('emailAddress')?.value,
        unRegisteredUser:
          this.enrollAutoPayForm.get('registerCustomer')?.value == true &&
          this.showRegisterOption === true
            ? 'RegisterEAPUPP'
            : 'EAPUPP',
        encryptedPaymentResult: payload,
        encryptedPayload: this.cpwaPayload,
        appAndBcRegistrations:
          this.enrollAutoPayForm.get('registerCustomer')?.value == true &&
          this.showRegisterOption === true,
        autoPayAccountDtoList: this.enrollAutoPaySubmited.autoPayUserAccounts,
      };

      this.enrollAutoPayService
        .saveAutoPayAccounts(quickPaySumitpayment)
        .subscribe(
          (paymentres: any) => {
            this.paymentInfo = '';
            this.paymentInformation = [];
            if (paymentres['serviceResponse']['type'] == 'success') {
              this.paymentInformation = paymentres['serviceResponse']['object'];
              this.showNextPage = false;
              this.paymentSuccessFlag = true;
            } else if (
              paymentres['serviceResponse']['type'] == 'error' &&
              paymentres['serviceResponse']['code'] == '8111'
            ) {
              this.declinedMessage = false;
              this.showNextPage = false;
            } else {
              this.declinedMessage = true;
              this.showNextPage = false;
            }
          },
          (err) => {
            console.log(err);
            this.declinedMessage = true;
            this.showNextPage = false;
          }
        );
    }
  }

  printPage() {
    window.print();
  }

  redirectToHome() {
    this.router.navigateByUrl('/').then(() => {
      window.location.reload();
    });
  }
}

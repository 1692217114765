import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { EnrollAutopayService } from 'src/app/services/enroll-autopay/enroll-autopay.service';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import { ManageAccountsService } from 'src/app/services/manage-accounts/manage-accounts.service';
import { OBPPModalDialogComponent } from '../../error-component/modal-dialog/modal-dialog.component';
import { LocalStorageService } from 'src/app/services/global/local-storage.service';

@Component({
  selector: 'obpp-manage-accounts',
  templateUrl: './obpp-manage-accounts.component.html',
  styleUrls: ['./obpp-manage-accounts.component.scss']
})
export class OBPPUserManageAccountsComponent implements OnInit {
  accountInformation: any = {};
  currentAccounts: string[] = [];
  manageAccountsForm: FormGroup = new FormGroup({
    addAccountForm: this.fb.group({
      accountNumber: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(12)]],
      postalCode: ['', [Validators.required, Validators.maxLength(7)]],
      accountAccess: ['Full Access', [Validators.required]],
      needToEnroll: [false, [Validators.required]],
      expectedPostalCode: ''
    }),
    additionalNotes: this.fb.control('', [Validators.maxLength(200)]),
    accountDetails: this.fb.array(
      [],
      [Validators.required, Validators.minLength(1), Validators.maxLength(5)]
    )
  });
  displayAddAccountPage = false;
  disableAddButton = false;
  isChangeSuccessful = false;
  isChangeError = false;
  duplicateAccountNumberError = false;
  optToEnroll = false;
  showCombinationError = false;
  isViewOnly: boolean = false;
  p: number = 1;

  constructor(private fb: FormBuilder, private modalService: NgbModal, private manageAccountsService: ManageAccountsService, private obppAuthService: OBPPLoginService, private router: Router, private obbppAutpPayService: EnrollAutopayService,
    public dataSharingService: DataSharingService, public localStorageService: LocalStorageService) {

    this.isViewOnly = this.obppAuthService.getIsViewOnly();
    let uname = '';
    if (this.isViewOnly) {
      uname = this.obppAuthService.getLookupUserName();
    } else {
      uname = this.obppAuthService.getUserName();
    }
    manageAccountsService.getAccountMasterDetails(uname, obppAuthService.x_csrf_token).subscribe((creditData: any) => {
      if (creditData && creditData.serviceResponse && creditData.serviceResponse.type && creditData.serviceResponse.type === "success" && creditData.serviceResponse.object != null) {
        this.accountInformation.accounts = creditData.serviceResponse.object;
        for (let i = 0; i < creditData.serviceResponse.object.length; i++) {
          let eachElm = creditData.serviceResponse.object[i];
          this.currentAccounts.push(eachElm.accountNumber);
          this.accountDetails.push(this.createManageBillingGroup(eachElm.accountNumber, eachElm.status, eachElm.access, eachElm.languagePref, eachElm.paperLessBilling,
            eachElm.contactName, eachElm.owner, eachElm.obppAutoPayAccountStatus, false, false));

        }
      }
    }, (error: any) => {
      console.error(error);
    }
    );
  }

  get accountDetails(): FormArray {
    return this.manageAccountsForm.get('accountDetails') as FormArray;
  }

  get addAccountForm(): FormGroup {
    return this.manageAccountsForm.get('addAccountForm') as FormGroup;
  }

  createManageBillingGroup(accountNumber: string, status: string, access: string, langPref: string, paperLess: boolean, contactName: string, owner: boolean, autopayStatus: string, isAddedNewly: boolean, enrollForAutoPay: boolean) {
    return this.fb.group({
      accountNumber: [accountNumber],
      status: [status],
      access: [access],
      languagePref: [langPref],
      paperLessBilling: [paperLess],
      contactName: [contactName, [Validators.required, Validators.maxLength(45)]],
      enrollForAutoPay: [{ value: enrollForAutoPay, disabled: autopayStatus === 'Enrolled' || (autopayStatus === 'Suspended' && owner == true) || (autopayStatus === 'PendingActivation') || autopayStatus === 'Locked for Processing' || autopayStatus === 'PendingAcceptance' || autopayStatus === 'PendingRemoval' }],
      obppAutoPayAccountStatus: [autopayStatus],
      isAddedNewly: isAddedNewly,
      removeAccess: false
    });
  }

  ngOnInit() {

  }

  isAccountPending(accNumberIndex: any) {
    return "P" === this.accountInformation.accounts[accNumberIndex].status
      || "R" === this.accountInformation.accounts[accNumberIndex].status;
  }

  addAccount(fromSave: boolean) {
    if (this.displayAddAccountPage) {
      if (this.addAccountForm?.dirty == true && this.addAccountForm?.valid == true) {
        let postalCode = (this.addAccountForm.get('postalCode')?.value).toUpperCase();
        let expectedPostalCode = this.addAccountForm.get('expectedPostalCode')?.value;
        if (expectedPostalCode == null || expectedPostalCode == undefined || expectedPostalCode == '') {
          this.checkAccountNumberAndPostalCode(true);
          expectedPostalCode = this.addAccountForm.get('expectedPostalCode')?.value;
        }

        if (postalCode == expectedPostalCode) {
          this.showCombinationError = false;
          this.accountDetails.push(this.createManageBillingGroup((this.addAccountForm.get('accountNumber')?.value).trim(), 'P', this.addAccountForm.get('accountAccess')?.value, '', false,
            '', false, '', true, this.addAccountForm.get('needToEnroll')?.value));
          this.currentAccounts.push((this.addAccountForm.get('accountNumber')?.value).trim());
          this.accountDetails.updateValueAndValidity({ emitEvent: true });
          this.addAccountForm.reset();
          this.addAccountForm.get('needToEnroll')?.setValue(false);
          this.addAccountForm.get('accountAccess')?.setValue('Full Access');
          this.addAccountForm.updateValueAndValidity({ emitEvent: true });
        } else if (fromSave == true) {
          this.showCombinationError = true;
        }
      }
    }
    this.displayAddAccountPage = true;
    this.duplicateAccountNumberError = false;
  }
  //When requiresServiceCall = true ensure to pass the service data as well..
  remove(requiresServiceCall: boolean, serviceData?: any) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = 'REMOVE_BILLING_CENTER_ACCOUNT';
    modalRef.componentInstance.my_modal_content = 'REMOVE_BILLING_CENTER_ACCOUNT_TEXT';
    modalRef.componentInstance.firstButtonLabel = 'REMOVE_ACCT_CONF_TEXT';
    modalRef.componentInstance.secondButtonLabel = 'CANCEL';
    modalRef.componentInstance.modalType = "warning";
    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      this.displayAddAccountPage = false;
      this.disableAddButton = false;
      this.showCombinationError = false;
      if (!requiresServiceCall) {
        this.addAccountForm.reset();
        this.addAccountForm.get('needToEnroll')?.setValue(false);
        this.addAccountForm.get('accountAccess')?.setValue('Full Access');
        this.addAccountForm.updateValueAndValidity({ emitEvent: true });
      } else {
        this.updateAccountDetails(serviceData);
      }
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {
      console.log($e);
    })
  }

  removeByIndex(index: any) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = 'REMOVE_BILLING_CENTER_ACCOUNT';
    modalRef.componentInstance.my_modal_content = 'REMOVE_BILLING_CENTER_ACCOUNT_TEXT';
    modalRef.componentInstance.firstButtonLabel = 'REMOVE_ACCT_CONF_TEXT';
    modalRef.componentInstance.secondButtonLabel = 'CANCEL';
    modalRef.componentInstance.modalType = "warning";
    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      let accountNumber = this.accountDetails.controls[index].get('accountNumber')?.value;
      this.currentAccounts.splice(this.currentAccounts.indexOf(accountNumber), 1);
      this.accountDetails.controls.splice(index, 1);
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {
      console.log($e);
    })
  }

  async checkAccountNumberAndPostalCode(sync?: boolean) {
    if (this.displayAddAccountPage == true && this.addAccountForm?.dirty == true && this.addAccountForm?.valid == true) {
      let accountNumber = (this.addAccountForm.get('accountNumber')?.value).trim();
      let postalCode = (this.addAccountForm.get('postalCode')?.value).toUpperCase();
      let expectedPostalCode = this.addAccountForm.get('expectedPostalCode')?.value;
      if (this.currentAccounts.indexOf(accountNumber) > 0) {
        this.duplicateAccountNumberError = true;
      } else if (expectedPostalCode == null || expectedPostalCode == undefined || expectedPostalCode == '') {
        this.dataSharingService.IsLoadingEnabled.next(true);
        if (sync) {
          const details = await this.manageAccountsService.checkAccountNumberWithAsync(accountNumber);
          this.handleAccountNumberRetrival(details, postalCode);
        } else {
          this.manageAccountsService.checkAccountNumber(accountNumber).subscribe((data: any) => {
            this.handleAccountNumberRetrival(data, postalCode);
          }, (error: any) => {
            this.dataSharingService.IsLoadingEnabled.next(false);
            console.error(error);
          });
        }
      } else if (postalCode == expectedPostalCode) {
        this.disableAddButton = false;
        this.duplicateAccountNumberError = false;
        this.showCombinationError = false;
      }
    }
  }
  handleAccountNumberRetrival(data: any, postalCode: any) {
    this.dataSharingService.IsLoadingEnabled.next(false);
    if (data && data.serviceResponse && data.serviceResponse.type && data.serviceResponse.type === "success") {
      if (data.serviceResponse.object && data.serviceResponse.object.accountNumber) {
        data.serviceResponse.object.accountBillingPostcode;
        this.addAccountForm.get('expectedPostalCode')?.setValue(data.serviceResponse.object.accountBillingPostcode);
        if (postalCode == data.serviceResponse.object.accountBillingPostcode) {
          this.disableAddButton = false;
        }
        return;
      }
    }
  }

  saveAccountDetails() {
    let postingData = [];
    let isRemovevalReq = false;
    if (this.displayAddAccountPage) {
      if (this.addAccountForm?.dirty == false || this.addAccountForm?.valid == false) {
        this.showCombinationError = true;
        return;
      } else {
        this.showCombinationError = false;
        this.addAccount(true);
        if (this.showCombinationError == false) {
          this.displayAddAccountPage = false;
        } else {
          return;
        }
      }
    }

    for (let i = 0; i < this.accountDetails.length; i++) {
      if (this.accountDetails.controls[i].get('isAddedNewly')?.value === true) {
        postingData.push(this.createNewAccountObject(this.accountDetails.controls[i]));
      } else if (this.accountDetails.controls[i]?.dirty == true) {
        let isChanged = false;
        if (this.accountDetails.controls[i].get('contactName')?.value != this.accountInformation.accounts[i].contactName) {
          isChanged = true;
        }
        if (this.accountDetails.controls[i].get('languagePref')?.value != this.accountInformation.accounts[i].languagePref) {
          isChanged = true;
        }
        if (this.accountDetails.controls[i].get('paperLessBilling')?.value != this.accountInformation.accounts[i].paperLessBilling) {
          isChanged = true;
        }
        if (this.accountDetails.controls[i].get('removeAccess')?.value == true) {
          isRemovevalReq = true;
          let newData = Object.assign({}, this.accountInformation.accounts[i]);
          newData.action = 'Remove';
          postingData.push(newData);
        } else if (isChanged === true) {
          let newData = Object.assign({}, this.accountInformation.accounts[i]);
          newData.action = 'Update';
          newData.languagePref = this.accountDetails.controls[i].get('languagePref')?.value;
          newData.contactName = this.accountDetails.controls[i].get('contactName')?.value;
          newData.paperLessBilling = this.accountDetails.controls[i].get('paperLessBilling')?.value;
          if (this.accountDetails.controls[i].get('enrollForAutoPay')?.disabled == false && this.accountDetails.controls[i].get('enrollForAutoPay')?.value == true) {
            newData.enrollForAutoPay = this.accountDetails.controls[i].get('enrollForAutoPay')?.value;
          }
          postingData.push(newData);
        }
        if (this.accountDetails.controls[i].get('enrollForAutoPay')?.disabled == false && this.accountDetails.controls[i].get('enrollForAutoPay')?.value == true) {
          this.dataSharingService.activeIdString.next("/user/autopay");
          this.router.navigateByUrl("/user/autopay");
        }
      }
    }
    if (postingData.length > 0) {
      this.isChangeError = false;
      let accountData = {
        accounts: postingData,
        additionalNotes: this.manageAccountsForm.get('additionalNotes')?.value,
        userEmailId: this.obppAuthService.userName
      }
      if (isRemovevalReq == true) {
        this.remove(true, accountData);
      } else {
        this.updateAccountDetails(accountData);
      }

    } else {
      this.isChangeError = true;
    }
  }


  createNewAccountObject(formControl: AbstractControl) {
    return {
      accountNumber: formControl.get('accountNumber')?.value,
      languagePref: '',
      paperlessBilling: '',
      contactName: '',
      status: "P",
      removeRequestedFlag: false,
      editedInCurrentSession: true,
      removeIcon: true,
      obppAutoPayStatus: '',
      action: 'New',
      access: formControl.get('access')?.value,
      isEditable: false,
      number: '',
      postalCode: formControl.get('postalCode')?.value,
      enrollForAutoPay: formControl.get('enrollForAutoPay')?.value
    };
  }

  updateAccountDetails(accountData: any) {
    this.dataSharingService.IsLoadingEnabled.next(true);
    this.manageAccountsService.saveAccountMasterDetails(accountData, this.obppAuthService.x_csrf_token).subscribe((creditData: any) => {
      this.dataSharingService.IsLoadingEnabled.next(false);
      let accountNumbers: string[] = [];
      for (let i = 0; i < accountData.accounts.length; i++) {
        if (accountData.accounts[i].action == 'Update' || accountData.accounts[i].action == 'New') {
          if (accountData.accounts[i]?.enrollForAutoPay == true) {
            accountNumbers.push(accountData.accounts[i].accountNumber);
          }
        }
      }
      if (accountNumbers.length == 0) {
        this.isChangeSuccessful = true;
      } else {
        const modalRef = this.modalService.open(OBPPModalDialogComponent);
        modalRef.componentInstance.my_modal_title = 'SAVE_ENROL_BILLING_CENTRE_HEADER_TEXT';
        modalRef.componentInstance.my_modal_content = 'SAVE_ENROL_BILLING_CENTRE_TEXT';
        modalRef.componentInstance.firstButtonLabel = 'OK';
        modalRef.componentInstance.modalType = "warning";
        modalRef.componentInstance.successAction.subscribe(($e: any) => {
          this.obbppAutpPayService.accountNumbers = accountNumbers;
          this.returntoAutopayClick();
        });
      }
    }, (error: any) => {
      this.dataSharingService.IsLoadingEnabled.next(false);
      console.error(error);
    }
    );
  }

  switchButton(event: any) {
    if (event.target.checked == true) {
      this.optToEnroll = true;
    } else {
      if (this.addAccountForm.get('needToEnroll')?.value == true) {
        return;
      }
      for (let i = 0; i < this.accountDetails.length; i++) {
        if (this.accountDetails.controls[i].get('enrollForAutoPay')?.disabled == false && this.accountDetails.controls[i].get('enrollForAutoPay')?.value == true) {
          return;
        }
      }
      this.optToEnroll = false;
    }
  }

  returnmanageAccountsClick() {
    let currentUrl = this.router.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.dataSharingService.activeIdString.next("/user/manage-accounts");
      this.router.navigate([currentUrl]);
    });

  }
  returntoAccountSummaryClick() {
    this.dataSharingService.activeIdString.next("/user/account-summary");
    this.router.navigateByUrl("/user/account-summary");
  }
  returntoAutopayClick() {
    this.dataSharingService.activeIdString.next("/user/autopay");
    this.router.navigateByUrl("/user/autopay");
  }
}
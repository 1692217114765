import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { Alert } from '../../../models/error-inline-message.model';

@Component({
  selector: 'error-inline-message',
  templateUrl: './error-inline-message.component.html',
  styleUrls: ['./error-inline-message.component.scss']
})
export class OBPPErrorInlineMessageComponent implements OnInit {

  @Input() alerts: Alert[] = [];
  @Input() lang:string = "";

  constructor() {

  }

  ngOnInit() {

  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

}
<div class="modal-header" [ngClass]="modalType == 'error' ? 'error' : modalType == 'warning' ? 'warning' : 'success'">
  <h3 class="modal-title w-100 text-center">{{my_modal_title | translate}}</h3>
</div>
<div class="modal-body">
  <p> {{my_modal_content | translate}}</p>

  <form [formGroup]="forgotForm">
  <div class="form-group input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text fa-icon-field" id="basic-addon1">
        <i class="fa fa-user" aria-hidden="true"></i>
      </span>
    </div>
    <input class="form-control" formControlName="useremail_forgot" placeholder="{{ 'LOGIN_EMAIL_PLACEHOLDER_KEY' | translate }}"
      type="email" aria-describedby="basic-addon1">
    
      <div class="w-100" *ngIf="forgotForm.get('useremail_forgot')?.invalid && forgotForm.get('useremail_forgot')?.dirty">
        <span class="error-block">{{ 'EMAIL_VALID' | translate }}</span>
    </div>
  </div>

  </form>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button [disabled]="forgotForm.invalid" type="button" *ngIf="firstButtonLabel" class="btn btn-primary btn-block primary-btn"
    (click)="successActionFunc()">{{firstButtonLabel | translate}}</button>
  <button type="button" *ngIf="secondButtonLabel" class="btn btn-primary cta-blue info-btn"
    (click)="cancelActionFunc()">{{secondButtonLabel | translate}}</button>
</div>
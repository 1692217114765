<div class="header-bg no-print">
  <obpp-loader-component *ngIf="isLoading"></obpp-loader-component>
  <obpp-payment-loader-component *ngIf="isPaymentInProgress"></obpp-payment-loader-component>

  <div class="container navbar-top-container">
    <nav class="navbar navbar-expand-lg bg-white header-title-border">
      <div class="container-fluid">
        <div class="navbar-header">
          <a class="navbar-brand" href="javascript:void(0);" href="{{ 'PUROLATOR_SITE_URL' | translate }}">
            <img alt="purolator logo" src="assets/images/logo-purolator.svg" />
          </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="nav navbar-nav navbar-right" [class]="
              !isHomePage &&
              this.usernameHeader != '' &&
              this.usernameHeader != null
                ? ''
                : 'nav-tab'
            ">
            <li role="presentation">
              <a class="nav-link text-color-primary" aria-current="page"
                *ngIf="!isLoggedUser && !isAdminUser && !isHomePage" (click)="homeLink()" [routerLink]="['/']">HOME</a>
              <a class="nav-link text-color-primary" aria-current="page" *ngIf="isLoggedUser && !isAdminUser"
                (click)="updateActiveId('/user/account-summary')" [routerLink]="['/user/account-summary']">HOME</a>
            </li>

            <li role="presentation" *ngIf="isHomePage">
              <a class="nav-link text-color-primary" href="#" (click)="scrollToLogin()" role="button"
                aria-expanded="false">LOGIN</a>
            </li>

            <li role="presentation" *ngIf="isHomePage">
              <a class="nav-link text-color-primary" href="#" (click)="scrollToLogin()" role="button"
                aria-expanded="false">QUICK PAY</a>
            </li>

            <li role="presentation" class="dropdown">
              <a class="nav-link nav-link-main dropdown-toggle text-color-primary" data-bs-toggle="dropdown" href="#"
                role="button" aria-expanded="false">SUPPORT</a>

              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item text-color-primary" target="_blank"
                    href="{{ 'CONTACT_US_URL' | translate }}"><span class="link-label">{{
                      "CONTACT_US" | translate
                      }}</span></a>
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="#" id="imageDropdown" data-toggle="dropdown" *ngIf="
                  !isHomePage &&
                  this.usernameHeader != '' &&
                  this.usernameHeader != null
                " data-bs-toggle="dropdown">
                <img src="../../../../assets/images/logout.svg" alt="logout" />
              </a>
              <ul class="dropdown-menu logout-menu" role="menu" aria-labelledby="imageDropdown">
                <li>
                  <a ngbDropdownItem [routerLink]="['/user/user-profile']">{{ "PROFILE" | translate }}<br />({{
                    usernameHeader }})</a>
                </li>
                <li>
                  <a ngbDropdownItem href="#" (click)="logout()">{{
                    "LOGOUT" | translate
                    }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <div class="container pt-4 pb-4 d-flex">
    <div *ngIf="isLoggedUser && !isHomePage && !isAdminUser" class="col-lg-2 sidebar">
      <ul class="list-ul">
        <li class="list-li">
          <span class="list-span"><img class="pr-5 w-24" src="../../../../assets/images/truck.svg">
            <a class="text-color-primary" target="_blank"
              href="https://www.purolator.com/en/shipping/holiday-shipping"><span class="link-label">{{
                "HOLIDAY_SHIPPING" | translate }}</span></a></span>
        </li>
        <li class="list-li">
          <span class="list-span"><img class="pr-5 w-24" src="../../../../assets/images/package.svg">
            <a class="text-color-primary" target="_blank" href="https://www.purolator.com/en/support/how-to-ship"><span
                class="link-label">{{ "HOW_TO_SHIP" | translate }}</span></a></span>
        </li>
        <li class="list-li">
          <span class="list-span"><img class="pr-5 w-24" src="../../../../assets/images/human.svg">
            <a class="text-color-primary" target="_blank" href="https://eshiponline.purolator.com/ShipOnline/"><span
                class="link-label">{{ "SCHEDULE_PCIKUP" | translate }}</span></a></span>
        </li>
        <li class="list-li">
          <span class="list-span"><img class="pr-5 w-24" src="../../../../assets/images/shipping.svg">
            <a class="text-color-primary" target="_blank" href="http://www.purolatorinternational.com/"><span
                class="link-label">{{ "US_SHIPPERS" | translate }}</span></a></span>
        </li>
        <li class="list-li">
          <span class="list-span"><img class="pr-5 w-22" src="../../../../assets/images/location.svg"><a
              class="text-color-primary" target="_blank" href="{{ 'FIND_LOCATION_URL' | translate }}"><span
                class="link-label">{{
                "FIND_LOCATION" | translate
                }}</span></a></span>
        </li>
        <li class="list-li">
          <span class="list-span"><img class="pr-5 w-22" src="../../../../assets/images/chat.svg"><a
              class="text-color-primary" tiltle="Chat" href="javascript:void(0);" (click)="openLiveChat()">
              {{'CHAT' | translate}}</a></span>
        </li>
      </ul>
    </div>

    <div *ngIf="isLoggedUser && !isHomePage && isAdminUser" class="col-lg-2">
      <ul class="list-ul">
        <li (click)="navigatetab('viewCustomerScreen', '')"
          [class]="activeTab==='viewCustomerScreen'?'list-li list-li-active d-flex align-items-center':'list-li'">
          <span [class]="activeTab==='viewCustomerScreen'?'list-span list-span-active':'list-span'"><img class="pr-5"
              [src]="activeTab==='viewCustomerScreen' ? '../../../../assets/images/card-checklist-active.svg':
              '../../../../assets/images/card-checklist.svg'">{{
            "VIEW_CUSTOMER_SCREENS" | translate }}</span>
        </li>
        <li (click)="navigatetab('manageusers','')"
          [class]="activeTab==='manageusers'?'list-li list-li-active':'list-li'">
          <span [class]="activeTab==='manageusers'?'list-span list-span-active':'list-span'">
            <img class="pr-5 w-24" [src]="activeTab==='manageusers' ? '../../../../assets/images/shield-check-active.svg':
              '../../../../assets/images/shield-check.svg'">{{
            "MANAGE_USERS" | translate }}</span>
        </li>
        <li (click)="navigatetab('transactionlog', '')"
          [class]="activeTab==='transactionlog'?'list-li list-li-active':'list-li'">
          <span [class]="activeTab==='transactionlog'?'list-span list-span-active':'list-span'"><img class="pr-5" [src]="activeTab==='transactionlog' ? '../../../../assets/images/transactionlog-active.svg':
              '../../../../assets/images/transactionlog.svg'">{{
            "TRANSACTION_LOG" | translate }}</span>
        </li>
        <li (click)="navigatetab('loosebills', '')"
          [class]="activeTab==='loosebills'?'list-li list-li-active':'list-li'">
          <span [class]="activeTab==='loosebills'?'list-span loosebills-active':'loosebills'"><img class="pr-5" [src]="activeTab==='loosebills' ? '../../../../assets/images/loosebills-active.svg':
              '../../../../assets/images/loosebills.svg'">{{ "LOOSE_BILLS_TEXT" | translate }}</span>
        </li>
        <li (click)="navigatetab('quickpay', '')" [class]="activeTab==='quickpay'?'list-li list-li-active':'list-li'">
          <span [class]="activeTab==='quickpay'?'list-span list-span-active':'list-span'"><img class="pr-5" [src]="activeTab==='quickpay' ? '../../../../assets/images/quickpay-active.svg':
              '../../../../assets/images/quickpay.svg'">{{ "QUICK_PAY_TEXT" | translate }}</span>
        </li>
        <li (click)="navigatetab('notificationlog','')"
          [class]="activeTab==='notificationlog'?'list-li list-li-active':'list-li'">
          <span [class]="activeTab==='notificationlog'?'list-span list-span-active':'list-span'"><img class="pr-5"
              [src]="activeTab==='notificationlog' ? '../../../../assets/images/notificationlog-active.svg':
              '../../../../assets/images/notificationlog.svg'">{{ "NOTIFICATION_LOG_TEXT" | translate }}</span>
        </li>
      </ul>
    </div>

    <div [class]="!isHomePage && isLoggedUser ? 'col-lg-10' : 'col-lg-12'">
      <div class="row mt-2">
        <error-inline-message [alerts]="alerts" [lang]="langLabel"></error-inline-message>
      </div>

      <div *ngIf="isHomePage" class="header-title">
        <h2 class="txt-puro-blue pull-left">
          {{ "SIMPLIFY_BILLING_PROCESS_TEXT" | translate }}
        </h2>
      </div>

      <div *ngIf="isHomePage" class="img-div">
        <img src="../../../../assets/images/login-image.png" alt="login-image"
          style="width: 1320px; margin-bottom: 2rem" />
        <div class="card register-card">
          <label class="txt-puro-blue card-title">
            {{ "NEW_TO_BILLINGCENTRE_TEXT" | translate }}
          </label>
          <a class="btn btn-primary cta-blue info-btn" [routerLink]="['/register']" (click)="updateNotHome()">
            {{ "REGISTER_TEXT" | translate }}
          </a>
        </div>

        <div class="card enroll-card">
          <label class="txt-puro-blue card-title">
            {{ "ENROL_WEEKLY_CREDIT_CARD_PROGRAM_TEXT" | translate }}
            <img *ngIf="this.langLabel.toLowerCase() != 'fr'" class="icon icon-tooltip"
              src="assets/images/icon-tooltip.svg" ngbTooltip="{{ 'ENROL_NEW_WELCOME' | translate }}" />
            <img *ngIf="this.langLabel.toLowerCase() == 'fr'" class="icon" src="assets/images/icon-tooltip.png"
              [ngbTooltip]="frqpcontent" />
          </label>
          <ng-template #frqpcontent>
            {{ "ENROL_NEW_WELCOME" | translate }}
            <br />
            {{ "ENROL_NEW_WELCOME2" | translate }}
          </ng-template>
          <a [routerLink]="['/enrollAutoPay']" (click)="updateNotHome()" class="btn btn-primary cta-blue info-btn">{{
            "ENROL_TEXT" | translate }}</a>
        </div>
      </div>

      <div class="col row" #loginQuickpay>
        <router-outlet></router-outlet>
      </div>
      <div class="p-6"></div>
    </div>
  </div>
</div>
<div class="container" *ngIf="!isChangeSuccessful">
    <div class="col-md-12">
        <div class="ml-20 mt-20">
            <h3 class="bold">{{'SETTINGS_ACCT_SUMMARY_TEXT' | translate}}</h3>
        </div>
    </div>
    <div *ngIf="isChangeError">
        <div class="row">
            <div class="col-md-12 pl-1">
                <span class="error-block">{{'SETTINGS_ACCT_CHANGES' |
                    translate}}</span>
            </div>
        </div>
    </div>
    <form [formGroup]="manageAccountsForm" class="register-form" novalidate>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead class="th-thead">
                    <tr>
                        <th>
                            <label class="control-label">
                                {{'ACCOUNT_NUMBER_TEXT'| translate}}
                            </label>
                        </th>
                        <th>
                            <label class="control-label">
                                {{'BC_ACCOUNT_STATUS_TEXT' | translate}}
                                <img class="icon icon-tooltip" src="../../../../assets/images/icon-tooltip.svg"
                                    ngbTooltip="{{'ACCOUNT_STATUS_TOOLTIP' | translate}}">
                            </label>
                        </th>
                        <th class="w-100">
                            <label class="control-label">
                                {{'BC_ACCOUNT_ACCESS_TEXT' | translate}}
                            </label>
                        </th>
                        <th class="w-175">
                            <label class="control-label">
                                {{'INVOICE_LANG_PREF_TEXT' | translate}}
                                <img class="icon icon-tooltip" src="../../../../assets/images/icon-tooltip.svg"
                                    ngbTooltip="{{'LANGUAGE_PREFERENCE_TOOLTIP' | translate}}">
                            </label>
                        </th>
                        <th class="w-100">
                            <label
                                [ngClass]="localStorageService.get('lang') == 'fr' ? 'control-label fr-text' : 'control-label'">
                                {{'PAPERLESS_TEXT' | translate}}
                                <img class="icon icon-tooltip" src="../../../../assets/images/icon-tooltip.svg"
                                    ngbTooltip="{{'PAPERLESS_BILLING_TOOLTIP_TEXT' | translate}}">
                            </label>
                        </th>
                        <th>
                            <label
                                [ngClass]="localStorageService.get('lang') == 'fr' ? 'control-label fr-text' : 'control-label'">
                                {{'CONTACT_NAME_TEXT' | translate }}
                            </label>
                        </th>
                        <th class="w-120">
                            <label class="control-label">
                                {{'AUTOPAY_STATUS' | translate }}
                            </label>
                        </th>
                        <th class="w-80">
                            <label
                                [ngClass]="localStorageService.get('lang') == 'fr' ? 'control-label fr-text' : 'control-label'">
                                {{'ENROL_MANAGE_USER_AUTOPAY_TEXT' | translate }}
                            </label>
                        </th>
                        <th>
                            <label class="control-label w-80">
                                {{'BC_REMOVE_ACCOUNT_ACCESS_TEXT' | translate }}
                                <img class="icon icon-tooltip" src="../../../../assets/images/icon-tooltip.svg"
                                    ngbTooltip="{{'REMOVE_ACCOUNT_TOOLTIP' | translate}}">
                            </label>
                        </th>
                    </tr>
                </thead>

                <tbody formArrayName="accountDetails" style="border-style: none;">
                    <tr *ngFor="let account of accountDetails.controls | paginate: 
                    { itemsPerPage: 5, currentPage: p} let i=index" [formGroupName]="i">
                        <td>
                            <span>
                                {{account.get('accountNumber')?.value}}
                            </span>
                        </td>
                        <td>
                            <span>
                                <div [ngSwitch]="account.get('status')?.value">
                                    <div *ngSwitchCase="'P'">{{'PENDING' | translate}}</div>
                                    <div *ngSwitchCase="'AP'">{{'APPROVED' | translate}}</div>
                                    <div *ngSwitchCase="'R'">{{'REJECTED' | translate}}</div>
                                </div>
                            </span>
                        </td>
                        <td>
                            <span>
                                <div [ngSwitch]="account.value.access">
                                    <div *ngSwitchCase="'No Access'">{{'NO_ACCESS_TEXT'
                                        | translate }}</div>
                                    <div *ngSwitchCase="'View Only'">{{'VIEW_ONLY_TEXT'
                                        | translate }}</div>
                                    <div *ngSwitchCase="'Full Access'">{{'FULL_ACCESS_TEXT'
                                        | translate }}</div>
                                </div>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="!account.value.isAddedNewly" class="d-flex">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" value="English"
                                        formControlName="languagePref">
                                    <label class="form-check-label">{{'ENGLISH_TEXT'
                                        | translate}}
                                    </label>
                                </div>
                                &nbsp;&nbsp;
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" value="French"
                                        formControlName="languagePref">
                                    <label class="form-check-label">{{'FRENCH_TEXT' | translate}}
                                    </label>
                                </div>
                            </span>
                        </td>
                        <td>
                            <div *ngIf="!account.value.isAddedNewly" class="form-check">
                                <input type="checkbox" formControlName="paperLessBilling"
                                    [disabled]="account.value.paperLessBilling"
                                    [checked]="account.value.paperLessBilling" />
                            </div>
                        </td>
                        <td>
                            <div *ngIf="!account.value.isAddedNewly">
                                <div class="input-group">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" formControlName="contactName"
                                            id="contactName" placeholder="Name" required=true aria-label="contactName"
                                            aria-describedby="basic-addon1" maxlength="40">
                                        <label for="contactName">Name</label>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span>
                                <label class="control-label">
                                    <div [ngSwitch]="account.value.obppAutoPayAccountStatus">
                                        <div *ngSwitchCase="'Not Enrolled'"><span title="{{'NOT_ENROLLED_TOOLTIP_TEXT'
                                    | translate }}">{{'NOT_ENROLLED_TEXT'| translate}}</span>
                                        </div>
                                        <div *ngSwitchCase="'Enrolled' || 'ENROLLED'"><span title="{{'ENROLLED_TOOLTIP_TEXT'
                                    | translate }}">{{'ENROLLED_TEXT'| translate}}</span>
                                        </div>
                                        <div *ngSwitchCase="'Suspended' || 'SUSPENDED'"><span title="{{'SUSPENDED_TOOLTIP_TEXT'
                                    | translate }}">{{'SUSPENDED_TEXT'| translate}}</span>
                                        </div>
                                        <div *ngSwitchCase="'Enrolled by Another User' || 'ENROLLEDBYANOTHERUSER'">
                                            <span title="{{'ENROLLEDBYOTHERUSER_TOOLTIP_TEXT'
                                    | translate }}">{{'ENROLLEDBY_ANOTHER_USER_TEXT'| translate}}</span>
                                        </div>
                                        <div *ngSwitchCase="'PendingActivation' || 'PENDINGACTIVATION'"><span title="{{'PENDINGACTIVATIONSTATUS_TOOLTIP_TEXT'
                                    | translate }}">{{'PENDING_ACTIVATION_TEXT'| translate}}</span>
                                        </div>
                                        <div *ngSwitchCase="'PendingRemoval' || 'PENDINGREMOVAL'">
                                            <span title="{{'PENDINGREMOVALSTATUS_TOOLTIP_TEXT'
                                    | translate }}">{{'PENDING_REMOVAL_TEXT'| translate}}</span>
                                        </div>
                                        <div *ngSwitchCase="'PendingAcceptance' || 'PENDINGACCEPTANCE'">
                                            <span title="{{'PENDINGACCEPTANCESTATUS_TOOLTIP_TEXT'
                                    | translate }}">{{'PENDING_ACCEPTANCE_TEXT'| translate}}</span>
                                        </div>
                                        <div *ngSwitchCase="'Locked for Processing'">
                                            <span title="{{'LOCKEDFORPROCESSING_TOOLTIP_TEXT'
                                    | translate }}">{{'LOCKED_FOR_PROCESSING_TEXT'| translate}}</span>
                                        </div>
                                    </div>
                                </label>
                            </span>
                        </td>
                        <td class="text-center">
                            <span>
                                <input type="checkbox" formControlName="enrollForAutoPay"
                                    (change)="switchButton($event)" [checked]="account.value.enrollForAutoPay" />
                            </span>
                        </td>
                        <td class="text-center">
                            <span *ngIf="!account.value.isAddedNewly">
                                <input type="checkbox" formControlName="removeAccess"
                                    [checked]="account.value.removeAccess" />
                            </span>
                            <span *ngIf="account.value.isAddedNewly" class="remove-account">
                                <img title="{{'REMOVE_ACCOUNT' | translate}}" class="icon icon-tooltip icon-position"
                                    src="assets/images/blueclose.svg" alt="Remove Access"
                                    (click)="removeByIndex(i)"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="text-center"></pagination-controls>
        </div>

        <div *ngIf="displayAddAccountPage">
            <div class="table-responsive">
                <table class="table">
                    <tbody formGroupName="addAccountForm" style="border-style: none;">
                        <tr style="height: 75px;">
                            <td>
                                <div class="input-group">
                                    <div class="form-floating">
                                        <input type="text" formControlName="accountNumber" class="form-control"
                                            minlength="2" maxlength="12" id="accountNumber" required="required"
                                            (focusout)="checkAccountNumberAndPostalCode()">
                                        <label for="accountNumber">{{'ACCOUNT_NUMBER_TEXT' | translate}}</label>
                                    </div>
                                    <img class="icon icon-tooltip icon-position"
                                        src="../../../../assets/images/icon-tooltip.svg"
                                        ngbTooltip="{{'REGISTER_ACCOUNT_NUMBER_TOOLTIP_TEXT_UPDATED' | translate}}">
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <div class="form-floating">
                                        <input type="text" formControlName="postalCode" class="form-control"
                                            minlength="4" maxlength="7" required="required"
                                            (focusout)="checkAccountNumberAndPostalCode()">
                                        <label for="postalCode">{{'POSTAL_CODE_TEXT' | translate}}</label>
                                    </div>
                                    <img class="icon icon-tooltip icon-position"
                                        src="../../../../assets/images/icon-tooltip.svg"
                                        ngbTooltip="{{'POSTALCODE_TOOLTIP_TEXT' | translate}}">
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <div class="form-floating w-175">
                                        <select formControlName="accountAccess" id="accountAddAccess"
                                            class="form-select" name="accessdrp">
                                            <option value="Full Access" selected>{{'FullAccess'|translate}}</option>
                                            <option value="View Only">{{'ViewOnly'|translate}}</option>
                                        </select>
                                        <label for="languagePref">{{'BILLING_CENTRE_ACCESS' | translate }}</label>
                                    </div>
                                    <img class="icon icon-tooltip icon-position" src="assets/images/icon-tooltip.svg"
                                        ngbTooltip="{{'BILLING_CENTRE_ACCESS_TOOLTIP' | translate}}">
                                </div>
                            </td>
                            <td class="text-center" style="vertical-align:top;">
                                <div class="d-flex flex-column align-items-center justify-content-around user-input-height">
                                    <label [ngClass]="localStorageService.get('lang') == 'fr' ? 'control-label fr-text' :
                                         'control-label'">
                                        {{'ENROL_MANAGE_USER_AUTOPAY_TEXT' | translate }}
                                    </label>
                                    <input type="checkbox" formControlName="needToEnroll"
                                        [checked]="addAccountForm.value.needToEnroll" (change)="switchButton($event)" />
                                </div>
                            </td>
                            <td>
                                <span class="remove-account">
                                    <img title="{{'REMOVE_ACCOUNT' | translate}}"
                                        class="icon icon-tooltip icon-position" src="assets/images/blueclose.svg"
                                        alt="Remove Access" (click)="remove(false)"></span>
                            </td>
                        </tr>
                        <tr class="border-0"
                            *ngIf="addAccountForm.get('accountNumber')?.invalid && addAccountForm.get('accountNumber')?.dirty">
                            <td colspan="5">
                                <div>
                                    <span class="error-block">{{'ERR_ACCT_MAINTENANCE' | translate }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="duplicateAccountNumberError">
                            <td colspan="5">
                                <div>
                                    <span class="error-block">{{'ERR_ACCT_EXISTS' |
                                        translate}}</span>
                                </div>
                            </td>
                        </tr>
                        <tr
                            *ngIf="addAccountForm.get('postalCode')?.invalid && addAccountForm.get('postalCode')?.dirty">
                            <td colspan="5">
                                <div>
                                    <span class="error-block">{{'INVALID_POSTAL_CODE_TEXT' | translate }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr
                            *ngIf="(addAccountForm.get('postalCode')?.valid && addAccountForm.get('postalCode')?.dirty && addAccountForm.get('expectedPostalCode')?.value
                            &&addAccountForm.get('expectedPostalCode')?.value != '' && addAccountForm.get('expectedPostalCode')?.value != addAccountForm.get('postalCode')?.value) || showCombinationError">
                            <td colspan="5">
                                <div>
                                    <span class="error-block">{{'POSTAL_CODE_ACCOUNT_NUMBER_COMBINATION' |
                                        translate}}

                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <button class="btn btn-primary cta-blue info-btn form-control cta-blue info-btn" (click)="addAccount(false)"
                    [disabled]="(addAccountForm.dirty == true && addAccountForm.valid == false) || (addAccountForm.dirty == false && displayAddAccountPage == true )
                        || (addAccountForm.dirty == true && addAccountForm.valid == true && addAccountForm.value.expectedPostalCode && addAccountForm.value.expectedPostalCode.toUpperCase().trim() != addAccountForm.value.postalCode.toUpperCase().trim())">{{'ADD_LABEL'
                    |
                    translate}}</button>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-7">
                <div class="form-floating h-100">
                    <input type="textarea" class="form-control mt-2" id="additionalMessages"
                        formControlName="additionalNotes" rows="3" style="max-width: 85%; height: 100%;">
                    <label for="additionalMessages">{{'ADDATIONAL_CHANGES_INCLUDE_TEXT' | translate }}</label>
                </div>
            </div>
        </div>
        <div class="row  mt-2">
            <div class="col-md-2">
                <div class="form-group">
                    <button class="btn btn-primary form-control primary-btn pl-15" (click)="saveAccountDetails()">{{
                        optToEnroll == true ? ('SAVE_AND_ENROL_TEXT' | translate) : ('SUBMIT_REQ_TEXT'|
                        translate)}}</button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="container" *ngIf="isChangeSuccessful">
    <div class="row">
        <div class="ml-20 mt-20">
            <h3 class="bold"><img class="icon me-3" src="assets/images/icon-alert-success.png">{{'BILLING_ACCNT_TEXT1' |
                translate}}</h3>
        </div>
    </div>
    <div class="row">
        <p class="col-md-12">{{'NOTIFICATION_ACCOUNT_TEXT'
            | translate}} </p>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-2 mb-3">
                <button class="btn btn-primary cta-blue info-btn form-control cta-blue info-btn"
                    (click)="returnmanageAccountsClick()">{{
                    'MANAGE_ACCOUNTS_TEXT' | translate }}</button>
            </div>
            <div class="col-md-2  mb-3">
                <button class="btn btn-primary form-control primary-btn" (click)="returntoAccountSummaryClick()">{{
                    'RET_ACCT_SUMMARY_TEXT' | translate }}</button>
            </div>
        </div>
    </div>
</div>
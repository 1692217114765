<p *ngFor="let alert of alerts" class="errors">
  <ngb-alert *ngIf="lang != 'fr'" [dismissible]="false" (closed)="close(alert)"
    class="d-flex align-items-start custom-alert">
    <p style="padding:10px">{{ alert.messageEn }}</p>
    <img class="icon close cursor-pointer" src="assets/images/icon-alert-general-remove.svg" (click)="close(alert)"
      alt="success">
  </ngb-alert>
  <ngb-alert *ngIf="lang == 'fr'" [type]="alert.type" [dismissible]="false" (closed)="close(alert)"
    class="custom-alertd-flex align-items-start custom-alert">
    <p style="padding:10px">{{ alert.messageFr }}</p>
    <img class="icon close cursor-pointer" src="assets/images/icon-alert-general-remove.svg" (click)="close(alert)"
      alt="success">
  </ngb-alert>
</p>
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AutoPayUserAccount,
  EnrollAutoPay,
} from 'src/app/models/enrollAutopay';
import { EnrollAutopayService } from 'src/app/services/enroll-autopay/enroll-autopay.service';
import { LocalStorageService } from 'src/app/services/global/local-storage.service';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import { OBPPModalDialogComponent } from '../../error-component/modal-dialog/modal-dialog.component';

@Component({
  selector: 'obpp-auto-pay',
  templateUrl: './obpp-auto-pay.component.html',
  styleUrls: ['./obpp-auto-pay.component.scss'],
})
export class OBPPUserAutoPayComponent implements OnInit {
  @ViewChild('init__payment') init__payment!: ElementRef;
  paymentSuccessFlag: boolean = false;
  serviceData = new Map();
  creditCardServiceData = new Map();
  showRegisterOption = false;
  creditCardDetails: any;
  autopayList: any;
  creditCardList: any;
  enrollAutoPayForm: FormGroup;
  disableAddAccount = false;
  showNextPage = false;
  errorMap = new Map();
  paymentInformation: any = [];
  declinedMessage: boolean = false;
  cpwaPayload: any;
  paymentInfo: string = ''; // will be deleted
  paymentURL = '';
  frameHeight = 624;
  cpwaQuickPayData: any;
  auotpayEncData: any;
  enrollAutoPaySubmited: any;
  isViewOnly: boolean = false;
  isPostalCheckNeeded: boolean = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private enrollAutoPayService: EnrollAutopayService,
    private obppAuthService: OBPPLoginService,
    private router: Router,
    private dataSharingService: DataSharingService,
    private obppLoginService: OBPPLoginService,
    private route: ActivatedRoute,
    public localStorageService: LocalStorageService
  ) {
    this.paymentURL = this.obppLoginService.getCPWAPaymentURL();
    this.isViewOnly = this.obppAuthService.getIsViewOnly();
    let uname = '';
    if (this.isViewOnly) {
      uname = this.obppAuthService.getLookupUserName();
    } else {
      uname = this.obppAuthService.getUserName();
    }
    enrollAutoPayService
      .fetchCurrentCreditCardList(uname, obppAuthService.x_csrf_token)
      .subscribe(
        (creditData: any) => {
          if (
            creditData &&
            creditData.serviceResponse &&
            creditData.serviceResponse.type &&
            creditData.serviceResponse.type === 'success' &&
            creditData.serviceResponse.object != null
          ) {
            this.creditCardDetails = creditData.serviceResponse.object;
            this.autopayList =
              creditData.serviceResponse.object.autoPayAccountDtoList;
            this.creditCardList =
              creditData.serviceResponse.object.creditCardDtoList;
          }
        },
        (error: any) => {
          console.error(error);
        }
      );

    let formArray: any;
    if (enrollAutoPayService.accountNumbers?.length > 0) {
      formArray = [];
    } else {
      formArray = [this.createEmpFormGroup()];
    }
    this.enrollAutoPayForm = this.fb.group({
      accountDetails: this.fb.array(formArray, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(5),
      ]),
    });
    if (enrollAutoPayService.accountNumbers.length > 0) {
      for (let i = 0; i < enrollAutoPayService.accountNumbers.length; i++) {
        this.loadAccountNumberDetailsFromAutopay(
          enrollAutoPayService.accountNumbers[i]
        );
      }
    }
    enrollAutoPayService.accountNumbers = [];
  }

  get accountDetails(): FormArray {
    return this.enrollAutoPayForm.get('accountDetails') as FormArray;
  }

  createEmpFormGroup() {
    return this.fb.group({
      accountNumber: [
        '',
        [
          Validators.minLength(2),
          Validators.maxLength(12),
          Validators.required,
        ],
      ],
      postalCode: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(7)],
      ],
      accountNumberOriginal: [''],
      expectedPostalCode: '',
      paymentDate: [''],
      autoPayFlag: [false, [Validators.required]],
      emailNotificationId: [
        '',
        [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
      isPreloaded: false,
      isPostalCodeDisabled: true,
      isCheckAccountBalance: false,
    });
  }

  createEmpFormGroupWithValue(
    accNum: string,
    paymentDate: string,
    autoPayFlag: boolean,
    emailNotificationId: string
  ) {
    return this.fb.group({
      accountNumber: [accNum, [Validators.required]],
      accountNumberOriginal: [accNum],
      postalCode: [Validators.required],
      expectedPostalCode: '',
      paymentDate: [paymentDate],
      autoPayFlag: [autoPayFlag, [Validators.required]],
      emailNotificationId: [
        emailNotificationId,
        [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
      isPreloaded: true,
      isPostalCodeDisabled: true,
      isCheckAccountBalance: false,
    });
  }

  createEmpFormGroupWithLoadeAutopayValue(
    accNum: string,
    paymentDate: string,
    expectedPostalCode: string
  ) {
    return this.fb.group({
      accountNumber: [accNum, [Validators.required]],
      accountNumberOriginal: [accNum],
      postalCode: ['', [Validators.required]],
      expectedPostalCode: '',
      paymentDate: [paymentDate],
      autoPayFlag: [false, [Validators.required]],
      emailNotificationId: [
        '',
        [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      ],
      isPreloaded: true,
      isPostalCodeDisabled: true,
      isCheckAccountBalance: false,
    });
  }

  ngOnInit(): void {}

  remove(itemId: any) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = 'REM_ACCT_CONF_TEXT';
    modalRef.componentInstance.my_modal_content = 'REMOVE_ACCT_SURE_CONF_TEXT';
    modalRef.componentInstance.firstButtonLabel = 'REMOVE_ACCT_CONF_TEXT';
    modalRef.componentInstance.secondButtonLabel = 'CANCEL';
    modalRef.componentInstance.modalType = 'warning';

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      this.accountDetails.controls.splice(itemId, 1);
      this.errorMap.delete(itemId);
      if (this.accountDetails.controls.length == 0) {
        this.accountDetails.controls.push(this.createEmpFormGroup());
      }
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {
      console.log($e);
    });
  }

  creditCardLoad(creditCardId: string) {
    if (this.autopayList) {
      this.accountDetails.clear();
      let i = 0;
      this.fetchCreditCardDetails(creditCardId);
      for (let autopayaccount of this.autopayList) {
        if (autopayaccount.creditcardid === creditCardId) {
          this.accountDetails.controls.push(
            this.createEmpFormGroupWithValue(
              autopayaccount.accountNumber,
              autopayaccount.targetActionDateFrontEnd,
              autopayaccount.sendSuccessfullNotificationInd,
              autopayaccount.carbonCopyEmailAddress
            )
          );
          this.accountDetails.updateValueAndValidity({ emitEvent: true });
          i++;
        }
      }
      this.disableAddAccount = false;
    }
  }

  accountNumberLoad(accountNumber: string) {
    if (this.autopayList) {
      this.accountDetails.clear();
      let i = 0;
      for (let autopayaccount of this.autopayList) {
        if (autopayaccount.accountNumber === accountNumber) {
          this.accountDetails.controls.push(
            this.createEmpFormGroupWithValue(
              autopayaccount.accountNumber,
              autopayaccount.targetActionDateFrontEnd,
              autopayaccount.sendSuccessfullNotificationInd,
              autopayaccount.carbonCopyEmailAddress
            )
          );
          this.disableAddAccount = true;
          this.checkAccountNumber(i);
          i++;
        }
      }
      this.accountDetails.updateValueAndValidity({ emitEvent: true });
    }
  }

  fetchCreditCardDetails(cardId: string) {
    if (cardId) {
      if (!this.creditCardServiceData.get(cardId)) {
        this.enrollAutoPayService.fetchAccountDetailsByCardId(cardId).subscribe(
          (accountData: any) => {
            if (
              accountData &&
              accountData.serviceResponse &&
              accountData.serviceResponse.type &&
              accountData.serviceResponse.type === 'success'
            ) {
              this.creditCardServiceData.set(
                cardId,
                accountData.serviceResponse.type.object
              );
              for (
                let i = 0;
                i < accountData.serviceResponse.object.length;
                i++
              ) {
                this.serviceData.set(
                  accountData.serviceResponse.object[i].accountNumber,
                  accountData.serviceResponse.object[i]
                );
              }
            }
          },
          (error: any) => {
            console.error(error);
          }
        );
      }
    }
  }

  checkAccountNumber(index: any) {
    let isValid =
      this.accountDetails.controls[index].get('accountNumber')?.valid;
    let accountNumber =
      this.accountDetails.controls[index].get('accountNumber')?.value;
    if (
      isValid === true &&
      this._checkIfAccountNumberExists(accountNumber) === false
    ) {
      this.errorMap.delete(index);
      this.enrollAutoPayService.checkAccountNumber(accountNumber).subscribe(
        (data: any) => {
          if (
            data &&
            data.serviceResponse &&
            data.serviceResponse.type &&
            data.serviceResponse.type === 'success'
          ) {
            if (!data.serviceResponse.object.scheduledNextInvoiceDate) {
              this.accountDetails.controls[index]
                .get('isPostalCodeDisabled')
                ?.setValue(true);
              this.accountDetails.controls[index]
                .get('paymentDate')
                ?.setValue('');
              this.openModal(
                'Auto Pay',
                'QUICK_PAY_ERROR',
                'OK',
                null,
                'warning'
              );
            }
            if (
              data.serviceResponse.object &&
              data.serviceResponse.object.accountNumber &&
              data.serviceResponse.object.scheduledNextInvoiceDate
            ) {
              let dateString =
                data.serviceResponse.object.scheduledNextInvoiceDate;
              let newString =
                dateString.substr(4, 2) +
                '-' +
                dateString.substr(6, 2) +
                '-' +
                dateString.substr(0, 4);
              this.accountDetails.controls[index]
                .get('paymentDate')
                ?.setValue(newString);
              this.accountDetails.controls[index]
                .get('expectedPostalCode')
                ?.setValue(data.serviceResponse.object.accountBillingPostcode);
              this.accountDetails.controls[index]
                .get('isPostalCodeDisabled')
                ?.setValue(false);
              this.accountDetails.controls[index]
                .get('isCheckAccountBalance')
                ?.setValue(true);

              this.enrollAutoPayService.fetchAccountId(accountNumber).subscribe(
                (accountData: any) => {
                  if (
                    accountData &&
                    accountData.serviceResponse &&
                    accountData.serviceResponse.type &&
                    accountData.serviceResponse.type === 'success'
                  ) {
                    data.serviceResponse.object.accountID =
                      accountData.serviceResponse.object;
                    this.enrollAutoPayService
                      .fetchAccountStatus(accountNumber)
                      .subscribe(
                        (accountStatusData: any) => {
                          if (
                            accountStatusData &&
                            accountStatusData.serviceResponse &&
                            accountStatusData.serviceResponse.type &&
                            accountStatusData.serviceResponse.type === 'success'
                          ) {
                            data.serviceResponse.object.obppAutoPayAccountStatus =
                              accountStatusData.serviceResponse.object.obppAutoPayAccountStatus;
                            data.serviceResponse.object.obppAutoPayAccountOwnerID =
                              accountStatusData.serviceResponse.object.obppAutoPayAccountOwnerID;

                            this.serviceData.set(
                              accountNumber,
                              data.serviceResponse.object
                            );
                          }
                        },
                        (error: any) => {
                          console.error(error);
                        }
                      );
                  }
                },
                (error: any) => {
                  console.error(error);
                }
              );
              return;
            }
          }
        },
        (error: any) => {
          console.error(error);
        }
      );
    } else if (
      this._checkIfAccountNumberExists(accountNumber) === true &&
      this._checkNoDuplicateAccountsByIndex(index)
    ) {
      this.errorMap.set(index, 'duperror');
    } else if (isValid === true) {
      this.errorMap.delete(index);
    }
  }

  checkPostalCode(index: any) {
    let isValid = this.accountDetails.controls[index].get('postalCode')?.valid;
    let postalCode =
      this.accountDetails.controls[index].get('postalCode')?.value;
    let expectedPostalCode =
      this.accountDetails.controls[index].get('expectedPostalCode')?.value;
    let cPcode = '^[a-zA-Z]{1}\\d{1}[a-zA-Z]{1} *\\d{1}[a-zA-Z]{1}\\d{1}$';
    let uPcode = '^\\d{5}(-\\d{4})?$';

    if (postalCode.match(cPcode) || postalCode.match(uPcode)) {
      if (expectedPostalCode !== postalCode) {
        this.accountDetails.controls[index].setErrors({ incorrect: true });
      }
    } else if (!postalCode) {
      this.accountDetails.controls[index].get('postalCode')?.markAsDirty();
    } else {
      this.accountDetails.controls[index].setErrors({ incorrect: true });
    }

    if (isValid === true) {
      if (
        expectedPostalCode === null ||
        expectedPostalCode === undefined ||
        expectedPostalCode === ''
      ) {
        this.checkAccountNumber(index);
      }
    }
  }

  openModal(
    title: string,
    errorMsg: string,
    firstButtonLabel: any,
    secondButtonLabel: any,
    modalType: string
  ) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      console.log($e);
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {
      console.log($e);
    });
  }

  loadAccountNumberDetailsFromAutopay(accountNumber: string) {
    if (this._checkIfAccountNumberExists(accountNumber) === false) {
      this.enrollAutoPayService.checkAccountNumber(accountNumber).subscribe(
        (data: any) => {
          if (
            data &&
            data.serviceResponse &&
            data.serviceResponse.type &&
            data.serviceResponse.type === 'success'
          ) {
            if (
              data.serviceResponse.object &&
              data.serviceResponse.object.accountNumber &&
              data.serviceResponse.object.scheduledNextInvoiceDate
            ) {
              let dateString =
                data.serviceResponse.object.scheduledNextInvoiceDate;
              var newString =
                dateString.substr(4, 2) +
                '-' +
                dateString.substr(6, 2) +
                '-' +
                dateString.substr(0, 4);
              this.accountDetails.push(
                this.createEmpFormGroupWithLoadeAutopayValue(
                  accountNumber,
                  newString,
                  data.serviceResponse.object.accountBillingPostcode
                )
              );
              this.enrollAutoPayService.fetchAccountId(accountNumber).subscribe(
                (accountData: any) => {
                  if (
                    accountData &&
                    accountData.serviceResponse &&
                    accountData.serviceResponse.type &&
                    accountData.serviceResponse.type === 'success'
                  ) {
                    data.serviceResponse.object.accountID =
                      accountData.serviceResponse.object;
                    this.serviceData.set(
                      accountNumber,
                      data.serviceResponse.object
                    );
                  }
                },
                (error: any) => {
                  console.error(error);
                }
              );

              this.enrollAutoPayService
                .fetchAccountStatus(accountNumber)
                .subscribe(
                  (accountData: any) => {
                    if (
                      accountData &&
                      accountData.serviceResponse &&
                      accountData.serviceResponse.type &&
                      accountData.serviceResponse.type === 'success'
                    ) {
                      data.serviceResponse.object.obppAutoPayAccountStatus =
                        accountData.serviceResponse.object.obppAutoPayAccountStatus;
                      data.serviceResponse.object.obppAutoPayAccountOwnerID =
                        accountData.serviceResponse.object.obppAutoPayAccountOwnerID;
                      this.serviceData.set(
                        accountNumber,
                        data.serviceResponse.object
                      );
                    }
                  },
                  (error: any) => {
                    console.error(error);
                  }
                );
              return;
            }
          }
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
  }

  _checkIfAccountNumberExists(accountNumber: string): boolean {
    return this.serviceData.get(accountNumber) != (undefined || null);
  }

  addAccount() {
    let currentLen = this.accountDetails.controls.length;
    if (
      this.accountDetails.controls[currentLen - 1].get('accountNumber')
        ?.valid &&
      this._checkNoDuplicateAccounts(currentLen)
    ) {
      this.accountDetails.controls.push(this.createEmpFormGroup());
      this.accountDetails.updateValueAndValidity({ emitEvent: true });
    }
  }
  private _checkNoDuplicateAccounts(currentLen: any) {
    for (let i = 0; i < currentLen; i++) {
      for (let j = 0; j < currentLen && i != j; j++) {
        if (
          this.accountDetails.controls[i].get('accountNumber')?.value ===
          this.accountDetails.controls[j].get('accountNumber')?.value
        ) {
          return false;
        }
      }
    }
    return true;
  }

  _convertDateToFrontEnddate(dateString: string): string {
    if (dateString === null || dateString === undefined || dateString === '')
      return '';
    return dateString.substring(0, 8);
  }

  register() {
    let isEnrollPayValid = this.enrollAutoPayForm?.valid;
    let isAccountDetailsValid = this.accountDetails?.valid;
    if (isEnrollPayValid === true && isAccountDetailsValid == true) {
      let isBusinessValid = true;
      let enrollAutoPay = new EnrollAutoPay();
      let accountNumbers: string[] = [];
      enrollAutoPay.userName = this.obppAuthService.userName;

      if (isBusinessValid == true) {
        for (let index = 0; index < this.accountDetails.length; index++) {
          if (this.accountDetails.controls[index]?.valid === false) {
            break;
          } else {
            let autopayUserAcc = new AutoPayUserAccount();
            let serviceData: any = this.serviceData.get(
              this.accountDetails.controls[index].get('accountNumber')?.value
            );
            if (serviceData) {
              autopayUserAcc.accountID = serviceData.accountID;
              autopayUserAcc.creditcardid = serviceData.creditcardid
                ? serviceData.creditcardid
                : '';
              autopayUserAcc.accountNumber = serviceData.accountNumber;
              autopayUserAcc.obppAutoPayAccountStatus =
                serviceData.obppAutoPayAccountStatus;
              autopayUserAcc.sendSuccessfullNotificationInd =
                this.accountDetails.controls[index].get('autoPayFlag')?.value;
              autopayUserAcc.carbonCopyEmailAddress =
                this.accountDetails.controls[index].get(
                  'emailNotificationId'
                )?.value;
              autopayUserAcc.targetActionDate =
                serviceData.scheduledInvoiceDate;
              autopayUserAcc.targetActionDateFrontEnd =
                serviceData.targetActionDateFrontEnd
                  ? serviceData.targetActionDateFrontEnd
                  : this._convertDateToFrontEnddate(
                      serviceData.scheduledNextInvoiceDate
                    );
              autopayUserAcc.postalCodeSAP = serviceData.accountBillingPostcode;
              autopayUserAcc.postalCode = serviceData.accountBillingPostcode;
              autopayUserAcc.obppPreviousAutoPayAccountStatus =
                serviceData.obppPreviousAutoPayAccountStatus;
              enrollAutoPay.autoPayAccountDtoList.push(autopayUserAcc);
            } else {
              return;
            }
          }
        }
        this.dataSharingService.IsLoadingEnabled.next(true);
        if (isBusinessValid === true) {
          this.enrollAutoPayService.setAutoPay(enrollAutoPay).subscribe(
            (data: any) => {
              if (
                data &&
                data.serviceResponse &&
                data.serviceResponse.type &&
                data.serviceResponse.type == 'success' &&
                data.serviceResponse.object != null
              ) {
                this.enrollAutoPaySubmited = enrollAutoPay;
                this.showNextPage = true;
                this.cpwaPayload = data.serviceResponse.object;
                this.auotpayEncData = enrollAutoPay;
                this.paymentURL = this.obppLoginService.getCPWAPaymentURL();
                setTimeout(() => {
                  this.init__payment.nativeElement.submit();
                }, 200);
              }
              this.dataSharingService.IsLoadingEnabled.next(false);
            },
            (error) => {
              this.dataSharingService.IsLoadingEnabled.next(false);
            }
          );
        }
      }
    }
  }
  showNextPageAction() {
    this.showNextPage = false;
  }

  removeAutoPayAccount(cardId: string, accountNumber: string) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = 'REM_ACCT_CONF_TEXT';
    modalRef.componentInstance.my_modal_content =
      'AUTOPAY_ENROLL_REMOVE_ACCOUNT_ALL';
    modalRef.componentInstance.firstButtonLabel = 'REMOVE_ACCT_CONF_TEXT';
    modalRef.componentInstance.secondButtonLabel = 'CANCEL';
    modalRef.componentInstance.modalType = 'warning';

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      if (cardId) {
        this.enrollAutoPayService.fetchAccountDetailsByCardId(cardId).subscribe(
          (accountData: any) => {
            if (
              accountData &&
              accountData.serviceResponse &&
              accountData.serviceResponse.type &&
              accountData.serviceResponse.type === 'success' &&
              accountData.serviceResponse.object
            ) {
              let requestBody = accountData.serviceResponse.object;
              for (let i = 0; i < requestBody.length; i++) {
                if (accountNumber === '-1') {
                  requestBody[i].userName = this.obppAuthService.userName;
                  requestBody[i].targetActionDate = null;
                } else if (requestBody[i].accountNumber === accountNumber) {
                  requestBody[i].userName = this.obppAuthService.userName;
                  requestBody[i].targetActionDate = null;
                  requestBody = Array.of(requestBody[i]);
                  break;
                }
              }
              this.enrollAutoPayService
                .removeAutoPayAccounts(
                  requestBody,
                  this.obppAuthService.x_csrf_token
                )
                .subscribe((data: any) => {
                  this.reloadCurrentRoute();
                });
            }
          },
          (error: any) => {
            console.error(error);
          }
        );
      }
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {
      console.log($e);
    });
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  private _checkNoDuplicateAccountsByIndex(i: any) {
    let currentLen = this.accountDetails.controls.length;
    for (let j = 0; j < currentLen && i != j; j++) {
      if (
        this.accountDetails.controls[i].get('accountNumber')?.value ===
        this.accountDetails.controls[j].get('accountNumber')?.value
      ) {
        return true;
      }
    }
    return false;
  }

  returnToManageUsers() {
    this.router.navigateByUrl('/home'), { relativeTo: this.route };
    this.dataSharingService.activeIdString.next('/home');
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent): void {
    if (event.data.event === 'cpwaResize') {
      this.frameHeight = event.data.height;
    }

    if (event.data.event === 'cpwaCommit') {
      let passData = event.data;
      let payload;
      let property: keyof typeof event.data; // Type is 'foo' | 'bar'
      for (property in event.data) {
        if (property === 'payload') {
          payload = event.data[property];
        }
      }

      let quickPaySumitpayment = {
        userName: this.obppLoginService.getUserName(),
        unRegisteredUser:
          this.enrollAutoPayForm.get('registerCustomer')?.value == true &&
          this.showRegisterOption === true
            ? 'RegisterEAPUPP'
            : 'EAPUPP',
        encryptedPaymentResult: payload,
        encryptedPayload: this.cpwaPayload,
        appAndBcRegistrations:
          this.enrollAutoPayForm.get('registerCustomer')?.value == true &&
          this.showRegisterOption === true,
        autoPayAccountDtoList: this.enrollAutoPaySubmited.autoPayAccountDtoList,
      };

      this.enrollAutoPayService
        .saveAutoPayAccounts(quickPaySumitpayment)
        .subscribe(
          (paymentres: any) => {
            this.paymentInfo = '';
            this.paymentInformation = [];
            if (paymentres['serviceResponse']['type'] == 'success') {
              this.paymentInformation = paymentres['serviceResponse']['object'];
              this.showNextPage = false;
              this.paymentSuccessFlag = true;
            } else if (
              paymentres['serviceResponse']['type'] == 'error' &&
              paymentres['serviceResponse']['code'] == '8111'
            ) {
              this.declinedMessage = false;
              this.showNextPage = false;
            } else {
              this.declinedMessage = true;
              this.showNextPage = false;
            }
          },
          (err) => {
            console.log(err);
            this.declinedMessage = true;
            this.showNextPage = false;
          }
        );
    }
  }

  printPage() {
    window.print();
  }

  getDisplayobppAutoPayAccountStatus(autoPayStatus: string) {
    switch (autoPayStatus) {
      case 'SUSPENDED':
        return 'SUSPENDED_TEXT';
      case 'Suspended':
        return 'SUSPENDED_TEXT';
      case 'ENROLLED':
        return 'ENROLLED_TEXT';
      case 'Enrolled':
        return 'ENROLLED_TEXT';
      case 'NOTENROLLED':
        return 'NOT_ENROLLED_TEXT';
      case 'Not Enrolled':
        return 'NOT_ENROLLED_TEXT';
      case 'NotEnrolled':
        return 'NOT_ENROLLED_TEXT';
      case 'PENDINGACTIVATION':
        return 'PENDING_ACTIVATION_TEXT';
      case 'PendingActivation':
        return 'PENDING_ACTIVATION_TEXT';
      case 'PENDINGACCEPTANCE':
        return 'PENDING_ACCEPTANCE_TEXT';
      case 'PendingAcceptance':
        return 'PENDING_ACCEPTANCE_TEXT';
      case 'pendingacceptance':
        return 'PENDING_ACCEPTANCE_TEXT';
      case 'PENDINGREMOVAL':
        return 'PENDING_REMOVAL_TEXT';
      case 'PendingRemoval':
        return 'PENDING_REMOVAL_TEXT';
      case 'Enrolled By Another User':
        return 'ENROLLEDBY_ANOTHER_USER_TEXT';
      case 'ENROLLEDBYANOTHERUSER':
        return 'ENROLLEDBY_ANOTHER_USER_TEXT';
      case 'Locked for Processing':
        return 'LOCKED_FOR_PROCESSING_TEXT';
      case 'LOCKEDFORPROCESSING':
        return 'LOCKED_FOR_PROCESSING_TEXT';
      default:
        return 'NOT_ENROLLED_TEXT';
    }
  }
}

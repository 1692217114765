import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OBPPModalForgotPasswordComponent } from 'src/app/common/shared/pop-up-modal/modal-forgot-password/modal-forgot-password.component';
import { OBPPModalDialogComponent } from '../../error-component/modal-dialog/modal-dialog.component';
import { UserDetailsModel } from '../../../models/user-details.model';
import { GlobalDataConfiguration } from '../../../data-config/global-data-config';
import { LocalStorageService } from '../../../services/global/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'obpp-login',
  templateUrl: './obpp-login.component.html',
  styleUrls: ['./obpp-login.component.scss']
})
export class OBPPLoginComponent implements OnInit {

  @Output() updateErrorMessage = new EventEmitter();
  content?: string;

  username: string = "";
  pwd: string = "";
  fieldErrorsObj: any = [];
  submitted: boolean = false;
  LoginForm: FormGroup;
  userDetails: UserDetailsModel = new UserDetailsModel();
  globalDataConfiguration: GlobalDataConfiguration = new GlobalDataConfiguration();
  showPassword: boolean = false;

  constructor(
    private obppAuthService: OBPPLoginService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private dataSharingService: DataSharingService
  ) {
    this.LoginForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        password: ['', Validators.required],
        remember: '',
      }
    )
  }

  ngOnInit() {
    this.submitted = false;
    let user = this.localStorageService.get(window.btoa("obppusername"));
    if (user) {
      this.LoginForm.get('email')?.setValue(window.atob(user));
      this.LoginForm.get('remember')?.setValue(true);
    }
    this.LoginForm.get('email')?.valueChanges.subscribe((event:any) => {
      this.LoginForm.get('email')?.setValue(event.toLowerCase(), {emitEvent: false});
   });
  }

  onSubmit() {
    this.submitted = true;
    if (this.LoginForm.valid && this.LoginForm.dirty) {
      let username = this.LoginForm.get("email")?.value;
      let pwd = this.LoginForm.get("password")?.value;
      let rem = this.LoginForm.get("remember")?.value;
      this.dataSharingService.IsLoadingEnabled.next(true);
      this.obppAuthService.login(username, pwd).subscribe(data => {
        let type = data.body.serviceResponse.type;
        if (type == "error") {
          this.fieldErrorsObj = [];
          this.fieldErrorsObj.push({ key: "", message: data.body.serviceResponse.message });
          this.updateErrorMessage.emit(this.fieldErrorsObj);
        } else if (type == "success") {
          this.userDetails.serviceResponse = data.body.serviceResponse.object;
          const language = data.body.serviceResponse.object.language.toLowerCase()
          this.translate.setDefaultLang(language)
          this.localStorageService.remove('lang')
          this.localStorageService.set('lang',language)
          this.dataSharingService.IsLoadingEnabled.next(false);
          const roleName = data.body.serviceResponse.object.roleDto.roleName;
          this.obppAuthService.userDetails['serviceResponse'] = data.body.serviceResponse.object;
          this.obppAuthService.x_csrf_token = data.headers.get("x-csrf-token");
          this.obppAuthService.userName = username;
          this.localStorageService.set(window.btoa("obpprole"), window.btoa(roleName));
          if (rem) {
            this.localStorageService.set(window.btoa("obppusername"), window.btoa(username));
          }
          this.dataSharingService.isUserLoggedIn.next(true);
          this.updateErrorMessage.emit([]);
          const role: any = this.userDetails.serviceResponse.roleDto

          if (role['roleName'] === "User" || role['roleName'] == "Customer") {
            this.router.navigateByUrl("/user/account-summary"), { relativeTo: this.route }
            this.dataSharingService.activeIdString.next("/user/account-summary");
          }else if (role['roleName'] === "Administrator"){
            console.log("admin");
            
            this.router.navigateByUrl("/manageusers"), { relativeTo: this.route }
            this.dataSharingService.activeIdString.next("/manageusers");
          }

          if(data.body.serviceResponse.object.roleDto && 
            data.body.serviceResponse.object.roleDto.roleName == 'Administrator') {
              this.router.navigateByUrl("/manageusers"), { relativeTo: this.route }
              this.dataSharingService.isAdminLoggedIn.next(true);
          } else {
            this.router.navigateByUrl("/user/account-summary"), {relativeTo: this.route}
            this.dataSharingService.activeIdString.next("/user/account-summary");
          }
          
        } else {
          var fieldErrorsObj = [];
          fieldErrorsObj = data.body.serviceResponse.object.fieldErrors;
          this.updateErrorMessage.emit(this.fieldErrorsObj);
        }
        this.dataSharingService.IsLoadingEnabled.next(false);
      }, err => {
        console.log(err);
        this.dataSharingService.IsLoadingEnabled.next(false);
      })
    }

  }


  forgotPassword() {
    let errMsg = "EMAIL_ENTER";
    let tit = "PASSWORD_FORGOT";
    this.sendForgotPassword(tit, errMsg, "OK", "CANCEL_TEXT", "warning");
  }


  sendForgotPassword(title: string, errorMsg: string, firstButtonLabel: any, secondButtonLabel: any, modalType: string) {
    const modalRef = this.modalService.open(OBPPModalForgotPasswordComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      this.obppAuthService.sendEmailForgotPassword($e).subscribe(res => {
        let errMsg = "FORGOT_PSWD_EMAIL_SENT_TEXT";
        let tit = "MAIL_SENT_SUCCESSFULLY";
        this.openModal("MAIL_SENT_SUCCESSFULLY", errMsg, "OK", null, "warning");
      }, error => {
        console.log(error);
        // let errMsg = "The temporary password has been successfully sent to your email address\n\n";
        // let tit = "Mail sent successfully";
        // this.openModal("MAIL_SENT_SUCCESSFULLY", "FORGOT_PSWD_EMAIL_SENT_TEXT", "OK", null, "warning");
      })
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {
      console.log($e);
    })
  }


  openModal(title: string, errorMsg: string, firstButtonLabel: any, secondButtonLabel: any, modalType: string) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      console.log($e);
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {
      console.log($e);
    })
  }


}
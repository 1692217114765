import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'obpp-home',
  templateUrl: './obpp-home.component.html',
  styleUrls: ['./obpp-home.component.scss']
})
export class HomeComponent implements OnInit {
  content?: string;

  fieldErrorsObj = [];

  

  constructor() { }

  ngOnInit() {
    console.log("Home Component");
  }


  updateErrorMessage(event: any) {
    this.fieldErrorsObj = event;
  }

}
<div class="container" *ngIf="isUpdateInProgress">

    <div class="row" *ngIf="errMsgFactory.length>0">
        <div class="col-md-12 error-block">
            <ul>
                <li *ngFor="let errMsg of errMsgFactory">
                    {{errMsg.value | translate}}
                </li>
            </ul>
        </div>
    </div>

    <div class="row" *ngIf="displayBalDueErrMsg">
        <div class="col-md-12 error-block">
            <span class="error-block">{{ 'AMOUNT_MORE_THAN_BALANCE_DUE' | translate }}</span>
        </div>
    </div>

    <div class="row" *ngIf="validAmountErrMsg">
        <div class="col-md-12 error-block">
            <span class="error-block">{{ 'VALID_AMOUNT_TEXT' | translate }}</span>
        </div>
    </div>

    <!-- <div class="flex-row account-row">
            <div class="btn-group account-dropdown w-100 p-bottom-dp" role="group">
                <div ngbDropdown class="w-100">
                    <button [disabled]="allAccountsList.length === 1" type="button"
                        class="btn btn-outline-secondary dropdown-toggle b-dis" id="dropdownBasic1" ngbDropdownToggle>
                        <span class="pull-left account-dropdown-header-leftContent ng-dd-span">{{'ALL_ACCOUNTS' | translate}}{{ddHeaderValue1}}</span>
                        <span class="float-end account-dropdown-trigger p-left-10 ng-dd-span"
                            *ngIf="allAccountsList.length > 1">
                            <img src="assets/images/icon-expand.png" alt="expand" />
                        </span>
                        <span class="float-end account-dropdown-header-rightContent">{{ 'TOTAL_BALANCE_DUE'| translate
                            }}
                            {{ddHeaderValue2 | currency}} </span>
                    </button>
                    <ul ngbDropdownMenu aria-labelledby="dropdownBasic1"
                        class="dropdown-menu account-dropdown-menu account-dropdown-menu-scrollable w-100" role="menu">
                        <li ngbDropdownItem (click)="setAllAccountValue(); searchFunc()">
                            <span class="pull-left account-dropdown-leftContent">{{'ALL_ACCOUNTS' | translate}}({{allAccountsList.length}})</span>
                            <span class="float-end account-dropdown-rightContent"> {{getTotalDueAmount() | currency }}
                            </span>
                            <div class="clearfix"></div>
                        </li>
                        <li ngbDropdownItem *ngFor="let accountRow of allAccountsList"
                            (click)="selectAccountNumber(accountRow)">
                            <span class="pull-left account-dropdown-leftContent">{{accountRow.accountName}} (A/C
                                #{{accountRow.accountNumber}})</span>
                            <span class="float-end account-dropdown-rightContent"> {{accountRow.totalAmountDue |
                                currency }} </span>
                            <div class="clearfix"></div>
                        </li>
                    </ul>
                </div>
            </div>
    </div> -->
  
    <div class="flex-row dispute-home-tab-bar" role="tabpanel" id="account-tabs-container">
        <div class="sectio-w-50">
            <div class="currency-date">
            <!-- Nav tabs -->
                <!-- <div class="p-left-10 d-flex col-md-4">
                    <p class="invTotal">
                        {{accountsList.length}} {{ 'INVOICES_IN_TOTAL' | translate }}
                    </p>
                </div> -->
                <div class="caurrency-area">
                    <p>Currency</p>
                    <div class="flex-row">                    
                        <input class="form-check-input s-24" type="checkbox" [(ngModel)]="isCAD"
                            [ngModelOptions]="{standalone: true}" (change)="searchFunc()" />
                        <span class="cr-name">CAD <img src="assets/images/canada-flag.png" class="flag" title="CAD"></span>
                        <input class="form-check-input s-24" type="checkbox" [(ngModel)]="isUSD"
                            [ngModelOptions]="{standalone: true}" (change)="searchFunc()" />
                        <span class="cr-name" >USD <img src="assets/images/american-flag.png" class="flag" title="USD"></span>
                    </div>    
                </div>
                <div class="form-floating">
                    <select class="form-select custom-select-date h-56" (click)="searchFunc()" [(ngModel)]="invoiceDateRange">
                        <option value="0">{{'All' | translate}}</option>
                        <option value="1">{{'PAST_MONTH' | translate}}</option>
                        <option value="3">{{'PAST_3_MONTH' | translate}}</option>
                        <option value="6">{{'PAST_6_MONTH' | translate}}</option>
                        <option value="12">{{'PAST_1_YEAR' | translate}}</option>
                        <option value="24">{{'PAST_2_YEAR' | translate}}</option>
                    </select>
                    <label for="floatingSelect">Date Range</label>
                </div>
            </div>
        </div>
        <div class="sectio-w-50 p-left-6">
            <div class="form-group search-term h-56">
                <div class="form-floating search-input">
                    <input id="searchTerm" [(ngModel)]="searchField"
                        title="{{'ACNT_SMRY_PLACE_HOLDER_KEY' | translate}}"
                        class="form-control placeholderChange"
                        maxlength="50" />
                    <label for="" class=" control-label">{{'ACNT_SMRY_PLACE_HOLDER_KEY' | translate }}</label>    
                </div>
                <div>
                    <button (click)="searchFunc()" type="button" class="btn btn-primary btn-block btn-dispute h-56">{{'SEARCH_KEY' | translate }}</button>
                </div>
                <div>
                    <button id="resetButton" type="button" class="btn btn-primary btn-block btn-dispute white-bg h-56"
                        (click)="clearActnSmrySearchResult()"
                        [disabled]="searchField == ''"
                    >
                        {{'RESET_KEY' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>



    <div class="acct-summy-tbl">
        <table>
            <thead>
                <tr>
                    <!-- <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col"
                        sortable="accountNumber" (sort)="onSort($event)">
                        <a href="javascript:void(0)">{{'ACCOUNT' |
                            translate
                            }}</a>
                    </th> -->
                    <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col" sortable="invoiceNumber"
                        (sort)="onSort($event)"><a href="javascript:void(0)">{{'INVOICE_NUMBER' |
                            translate }}</a></th>
                    <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col" sortable="invoiceDate"
                        (sort)="onSort($event)"><a href="javascript:void(0)">{{'INVOICE_DATE' |
                            translate
                            }}</a></th>
                    <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col" sortable="dueDate"
                        (sort)="onSort($event)"><a href="javascript:void(0)">{{'DUE_DATE' |
                            translate
                            }}</a>
                    </th>
                    <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col"
                        sortable="invoiceAmount" (sort)="onSort($event)"><a href="javascript:void(0)">{{'INVOICE_AMOUNT'
                            |
                            translate }} </a></th>
                    <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col" sortable="balanceDue"
                        (sort)="onSort($event)"><a href="javascript:void(0)">{{'BALANCE_DUE' |
                            translate
                            }} </a></th>
                    <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col" sortable="currency"
                        (sort)="onSort($event)"><a href="javascript:void(0)">{{'CURRENCY_TEXT' |
                            translate
                            }} </a></th>
                    <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col"><a
                            href="javascript:void(0)">{{'DISPUTE' |
                            translate
                            }} </a></th>
                    <th class="accountquickPay-tab-header account-tab-accNoqp" scope="col"><a
                            href="javascript:void(0)">{{'VIEW_PRINT_KEY' |
                            translate
                            }} </a></th>
                    <th>&#160;&#160;&#160;&#160;</th>
                </tr>
            </thead>
            <tbody>   
                <tr *ngFor="let name of accountName" class="account-row">
                    <td colspan="13" class="padding-0">
                        <ngb-accordion>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <!-- (click)="$event.stopPropagation();openDisputePage(invoice)" -->
                                    <div class="text-start p-left-2 label-account-info">{{ name[1] }} - {{name[0]}}</div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div *ngFor="let invoice of accountsList; index as i">
                                        <ngb-accordion *ngIf="name[1] === invoice.accounts['accountNumber']">
                                            <ngb-panel class="acct-summy-pnl" [disabled]="invoice?.pins?.length <= 0">
                                                <ng-template ngbPanelTitle>
                                                    <div class="w-100">
                                                        <table>
                                                            <tr>
                                                                <td class="w-pr-12 text-start cell-account-info">
                                                                    {{invoice['invoiceNumber']}}</td>
                                                                <td class="w-pr-13 text-start cell-account-info">{{invoice.invoiceDate}}
                                                                </td>
                                                                <td class="w-pr-13 text-start cell-account-info">{{invoice.dueDate}}
                                                                </td>
                                                                <td class="w-pr-10 text-end cell-account-info">{{
                                                                    invoice['invoiceAmount'] | currency }}
                                                                </td>
                                                                <td class="w-pr-15 text-end cell-account-info">{{ invoice['balanceDue']
                                                                    | currency }}
                                                                </td>
                                                                <td class="w-pr-13 p-left-7 text-center cell-account-info">
                                                                    {{invoice['currency']}}</td>
                                                                <td class="w-date text-center">
                                                                    <button class="btn btn-primary white-bg text-bold-14 blue-hover"
                                                                        (click)="$event.stopPropagation();openDisputePage(invoice)">{{
                                                                        'DISPUTE' | translate }}</button>
                                                                </td>
                                                                <td *ngIf="invoice?.pins?.length > 0" class="text-center">
                                                                    <img src="assets/images/icon-pdf-red.png"
                                                                        class="m-right-10 p-left-2" title="PDF"
                                                                        (click)="$event.stopPropagation();getInvoicePDF(invoice['invoiceNumber'])">
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="table-scrollable">
                                                        <table class="table table-striped">
                                                            <thead>
                                                                <th scope="col" sortable="service" (sort)="onSortPIN($event, invoice)">
                                                                    <a href="javascript:void(0)">Service</a>
                
                                                                </th>
                
                                                                <th scope="col" sortable="itemNumber"
                                                                    (sort)="onSortPIN($event, invoice)">
                                                                    <a href="javascript:void(0)">{{'ITEM_NUMBER' |
                                                                        translate
                                                                        }}</a>
                
                                                                </th>
                
                                                                <th scope="col" sortable="pinNumber"
                                                                    (sort)="onSortPIN($event, invoice)">
                                                                    <a href="javascript:void(0)">{{'PIN_NUMBER' |
                                                                        translate
                                                                        }}</a>
                
                                                                </th>
                                                                <th class="text-end pr-4" scope="col" sortable="totalCharges"
                                                                    (sort)="onSortPIN($event, invoice)">
                                                                    <a href="javascript:void(0)">{{'PIN_AMOUNT' |
                                                                        translate
                                                                        }}</a>
                
                                                                </th>
                                                                <th class="text-end" scope="col" sortable="balanceDue"
                                                                    (sort)="onSortPIN($event, invoice)">
                                                                    <a href="javascript:void(0)">{{'BALANCE_DUE' |
                                                                        translate
                                                                        }}</a>
                                                                </th>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let pin of invoice['pins']; index as j">
                
                
                                                                    <td class="invoice-invoiceNumberCell text-center cell-account-info">
                                                                        {{pin.service}}</td>
                                                                    <td class="invoice-invoiceNumberCell text-center cell-account-info">
                                                                        {{pin.itemNumber}}</td>
                                                                    <td class="invoice-accountNumberCell text-center cell-account-info">
                                                                        <a title="{{invoice.accountName}}" [routerLink]=""
                                                                            (click)="getShipmentTrackingURL(pin, invoice)">{{pin.pinNumber}}</a>
                                                                    </td>
                                                                    <td class="invoice-amountDueCell text-end pr-6 cell-account-info">
                                                                        {{pin.totalCharges |
                                                                        currency}}</td>
                                                                    <td class="invoice-balanceDueCellqp text-end p-3 cell-account-info">
                                                                        {{pin.balanceDue | currency}}
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="invoice?.pins?.length <= 0">
                                                                    <td colspan="12"><span
                                                                            class="txt-puro-red">{{'NO_OPEN_INVOICES_TEXT'|translate}}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </td>
                </tr>
                <tr *ngIf="accountsList.length <= 0">
                    <td colspan="12"><span class="txt-puro-red">{{'NO_OPEN_INVOICES_TEXT'|translate}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>

</div>


<div class="container" *ngIf="!isUpdateInProgress && !displaySuccessDisputeInvoice">

    <div class="dispute-invoice-header-box flex-row">
        <div class="invoice-detail flex-row">
            <div class="heading-name">{{ 'DISPUTE_INVOICE' | translate }}</div>
            <div class="heading-number"> # {{selectedInvoice.invoiceNumber}}</div>
        </div>
        <div class="invoice-detail flex-row">
            <div class="heading-name">{{ 'BALANCE_DUE' | translate }}</div>
            <div class="heading-number">{{selectedInvoice.balanceDue |currency}}</div>
        </div>
    </div>

    <div class="dispute-invoice-content-box flex-row">
        <div class="form-box">
            <div class="info-box mb-4">{{ 'YOUR_REQUEST_REVIEWED' | translate }}</div>
            <div class="mandatory-mesaage mb-4">{{ 'TL_ONE_OF_THE_FIELDS_MANDATORY' | translate}}</div>

            <div class="form-floating mb-4">
                <input name="disputeAmount" type="text" min="0.01" max="selectedInvoice.balanceDue"
                    [ngClass]="{'is-invalid': checkDisputeAmout()}"
                    class="form-control currency" [(ngModel)]="selectedInvoice.dispute.disputeAmount"
                    [ngModelOptions]="{standalone: true}" obppTwoDigitDecimaNumber />
                <label for="" class=" control-label">{{ 'DISPUTE_AMOUNT'| translate }}<span>*</span></label>    
                <div *ngIf="checkDisputeAmout()">
                    <span class="error-block p-left-4">{{ 'VALID_AMOUNT_KEY' | translate }}</span>
                </div>
            </div>

            <div class="form-floating mb-4">
                <textarea type="text" class="form-control tarea" row="3" [(ngModel)]="selectedInvoice.disputePINS"
                        [ngModelOptions]="{standalone: true}"></textarea>
                <label for="" class=" control-label">{{ 'PIN_NUMBER'| translate }}<span>*</span></label>
                <div *ngIf="false">
                    <span class="error-block p-left-4">{{ 'VALID_AMOUNT_KEY' | translate }}</span>
                </div>
            </div>

            <div class="form-floating mb-4">
                <select class="form-select custom-select" id="floatingSelect" [(ngModel)]="selectedInvoice.dispute.disputeReason">
                    <option *ngFor="let op of dispute_reason_options" value="{{op.code | translate}}">
                        {{op.description | translate}}
                    </option>
                </select>
                <label for="floatingSelect">{{ 'DISPUTE_REASON'  | translate }}<span>*</span></label>
                <div *ngIf="selectedInvoice.dispute.disputeReason == ''">
                    <span class="error-block p-left-4">{{ 'VALID_DISPUTE_REASON_KEY' | translate }}</span>
                </div>
            </div>

            <div class="form-floating mb-4">
                <textarea class="form-control placeholderChange tarea" style="height: 100px"
                        [(ngModel)]="selectedInvoice.dispute.note" [ngModelOptions]="{standalone: true}"
                        placeholder="{{ 'ADDITIONAL_INFO' | translate}}" maxlength="200"></textarea>
                <label for="" class=" control-label">{{ 'NOTES' | translate }}</label>
            </div>

            <div class="w-45">
                <div class="flex-row">
                    <div>
                        <button class="btn btn-primary btn-block btn-dispute" (click)="submitDispute()" [disabled]="isViewOnly">
                            {{ 'SUBMIT_DISPUTE' | translate }}
                        </button>
                    </div>
                    <div>
                        <button class="btn btn-primary cta-blue info-btn" (click)="goBackSummaryPage()">
                            {{ 'CANCEL' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="list-box">
            <div class="mt-6">
                <p class="content-text">
                    {{ 'YOU_MAY_ALSO_CHAT_TEXT' | translate }}
                </p>
            </div>
    
            <div class="mt-6">
                <div class="table-box">
                    <table>
                        <thead>
                            <th class="accountquickPay-tab-header account-tab-accNoqp w-pinnum" scope="col"
                                sortable="pinNumber" (sort)="onSortPINTable($event)">
                                <a href="javascript:void(0)" class="pinheaderhref">{{'PIN_NUMBER' |
                                    translate
                                    }}</a>

                            </th>
                            <th class="accountquickPay-tab-header account-tab-accNoqp w-pinnum text-center" scope="col"
                                sortable="service" (sort)="onSortPINTable($event)">
                                <a href="javascript:void(0)" class="pinheaderhref">Service</a>

                            </th>
                            <th class="accountquickPay-tab-header account-tab-accNoqp w-pinnum text-end" scope="col"
                                sortable="totalCharges" (sort)="onSortPINTable($event)">
                                <a href="javascript:void(0)" class="pinheaderhref">{{'PIN_AMOUNT' |
                                    translate
                                    }}</a>

                            </th>
                            <th class="accountquickPay-tab-header account-tab-accNoqp w-pinnum text-end" scope="col"
                                sortable="dueDate" (sort)="onSortPINTable($event)">
                                <a href="javascript:void(0)" class="pinheaderhref">{{'BALANCE_DUE' |
                                    translate
                                    }}</a>

                            </th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pin of selectedInvoice['pins']; index as j">
                                <td>
                                    <a title="{{selectedInvoice.accountName}}" [routerLink]=""
                                        (click)="getShipmentTrackingURL(pin, selectedInvoice)">{{pin.pinNumber}}</a>
                                </td>
                                <td class="text-center">{{pin.service}}</td>
                                <td class="text-end" >
                                    {{pin.totalCharges | currency}}
                                </td>
                                <td class="text-end" >
                                    {{pin.balanceDue | currency}}
                                </td>
                            </tr>
                            <tr *ngIf="selectedInvoice?.pins?.length <= 0">
                                <td colspan="4"><span class="txt-puro-red">{{'NO_OPEN_INVOICES_TEXT'|translate}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> 
        </div>
    </div>
</div>

<div class="container" *ngIf="displaySuccessDisputeInvoice">

    <div class="row form-group col-md-12 mb-4 mt-6">
        <h4 class="txt-puro-blue description-addAccount text-14">{{ 'DISPUTE_INVOICE_SUCCESS_TEXT1' | translate }}
            {{successDisputeObj.caseNumber}}</h4>
    </div>
    <div class="row form-group txt-puro-blue col-md-12 mb-4">
        <div class="col-md-3">
            <label for="qp-accNumber" class="control-label">{{ 'DISPUTE_INVOICE_NUMBER' | translate }}</label>
        </div>
        <div class="col-md-3">
            <label name="qp-accNumber" class="control-label">{{successDisputeObj.invoiceNumber}}</label>
        </div>    
    </div>
    <div class="row no-print col-md-12 mb-4">
        <h4 class="txt-puro-blue description-addAccount ng-binding text-14">{{ 'DISPUTE_INVOICE_SUCCESS_TEXT2' | translate
            }}</h4>
        <h4 class="txt-puro-blue description-addAccount ng-binding text-14">{{ 'DISPUTE_INVOICE_SUCCESS_TEXT3' | translate
            }}</h4>
    </div>
    <div class="txt-puro-blue row form-group col-md-12">
        <div class="col-md-3">
            <label for="qp-accNumber" class="control-label">{{ 'BALANCE_DUE_TEXT' | translate }}</label>
        </div>
        <div class="col-md-5">
            <label name="qp-accNumber" class="control-label">{{successDisputeObj.balanceDue |currency}} </label>
        </div>
    </div>
    <!-- End Account Number -->
    <!-- Invoice Number -->
    <div class="txt-puro-blue row form-group col-md-12">
        <div class="col-md-3">
            <label for="qp-invoiceNumber" class="control-label">{{ 'DISPUTE_AMOUNT' | translate }}</label>
        </div>
        <div class="col-md-5">
            <label name="qp-invoiceNumber" class="control-label">{{successDisputeObj.disputeAmount |currency}} {{
                'CAD_TEXT'
                | translate}}</label>
        </div>
    </div>
    <!-- End Invoice Number -->
    <!-- Pay Amount -->
    <div class="txt-puro-blue row form-group col-md-12">
        <div class="col-md-3">
            <label for="qp-payAmount" class="control-label">{{ 'DISPUTE_REASON' | translate }}</label>
        </div>
        <div class="col-md-5">
            <label name="qp-invoiceNumber" class="control-label">
                <div [ngSwitch]='successDisputeObj.disputeReason'>
                    <div *ngIf="localStorageService.get('lang') != 'fr'">
                        <div *ngSwitchCase="'Shipment Issue'">{{'DISPUTE_REASON_DESC_1' | translate }}</div>
                        <div *ngSwitchCase="'Pricing Error'">{{'DISPUTE_REASON_DESC_2' | translate }}</div>
                        <div *ngSwitchCase="'Other'">{{'DISPUTE_REASON_DESC_3' | translate }}</div>
                        <div *ngSwitchDefault>{{successDisputeObj.disputeReason}}</div>
                    </div>
                    <div *ngIf="localStorageService.get('lang') == 'fr'">
                        <div *ngSwitchCase="'Probléme avec l\'envoi'">{{'DISPUTE_REASON_DESC_1' | translate }}</div>
                        <div *ngSwitchCase="'Erreur de prix'">{{'DISPUTE_REASON_DESC_2' | translate }}</div>
                        <div *ngSwitchCase="'Autre'">{{'DISPUTE_REASON_DESC_3' | translate }}</div>
                        <div *ngSwitchDefault>{{successDisputeObj.disputeReason}}</div>
                    </div>

                </div>
            </label>
        </div>
    </div>

    <div class="d-flex form-group mt-6">
        <div class="returnToDisputeLink">
            <button class="btn btn-primary btn-dispute" (click)="returnToDispute()" >{{ 'RETURN_TO_DISPUTE_TEXT' | translate }}</button>
        </div>
        <div class="no-print">
            <button class="btn btn-primary btn-dispute ml-4" (click)="printPage()">{{ 'PRINT_TEXT' | translate }}</button>
        </div>
    </div>


</div>
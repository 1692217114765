
  <div class="row" *ngIf="fieldErrorsObj && fieldErrorsObj.length > 0">
    <div class="col-md-12 alert show alert-danger fade">
      <ul>
          <li *ngFor="let errMsg of fieldErrorsObj">
              {{errMsg['message'] | translate}}
          </li>
      </ul>
  </div>
</div>
<div class="d-flex justify-content-between" >

  <obpp-login (updateErrorMessage)="updateErrorMessage($event)"></obpp-login>


  <obpp-quick-pay-anonymous></obpp-quick-pay-anonymous>

</div>
  <router-outlet></router-outlet>
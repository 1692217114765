import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { OBPPQuickPayService } from 'src/app/services/quick-pay/quick-pay.service';
import { QuickPaySecurityAudit } from 'src/app/models/new-user-registration.model';
import { Router } from '@angular/router';
import { OBPPModalDialogComponent } from 'src/app/components/error-component/modal-dialog/modal-dialog.component';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';

@Component({
  selector: 'modal-quick-pay-invoice',
  templateUrl: './modal-quick-pay-invoice.component.html',
  styleUrls: ['./modal-quick-pay-invoice.component.scss']
})
export class OBPPQuickPayInvoiceComponent implements OnInit {

  @Input() my_modal_title: string = "";
  @Input() my_modal_content: string = "";
  @Input() firstButtonLabel: string = "";
  @Input() secondButtonLabel: string = "";
  @Input() modalType: string = "";
  @Input() accountNumber: any = [];
  @Output() successAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();

  isDuplicatePay: boolean = false;
  isLoading: boolean = false;
  secAuditData?: QuickPaySecurityAudit | any;

  QuickPayInvoiceForm!: FormGroup;
  userEmailID: string = "";

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private obppQuickPayService : OBPPQuickPayService,
    private route: Router,
    private modalService: NgbModal,
    private obppAuthService: OBPPLoginService
    ) {
    this.QuickPayInvoiceForm = this.fb.group( {
      accountNumber: ['', Validators.required],
      invoiceNumber: ['', Validators.required]
    })
  }

  ngOnInit() {

  }

  successActionFunc() {
    this.checkDuplicateAccount();
    if(this.QuickPayInvoiceForm.valid && this.QuickPayInvoiceForm.dirty && this.validateFields() && !this.isDuplicatePay) {
      this.quickPayFunction();
    }
  }

  cancelActionFunc() {
    this.userEmailID = "";
    this.cancelAction.emit("");
    this.activeModal.close("Cancel");
  }

  checkDuplicateAccount() {
    this.isDuplicatePay = false;
    if(this.accountNumber.find((a:any) => a == this.QuickPayInvoiceForm.get('accountNumber')?.value)) {
      this.isDuplicatePay = true;
    }
  }

  validateFields() {
    this.QuickPayInvoiceForm.get('accountNumber')?.markAsDirty();
    this.QuickPayInvoiceForm.get('invoiceNumber')?.markAsDirty();
    if (this.QuickPayInvoiceForm.valid) {
      return true;
    }
    return false;
  }


  getQuickPayData(accountNumber: string, inv: string) {
    this.isLoading = true;
    this.secAuditData = {
      accountNumber: accountNumber,
      attemptEntryPage: 'QuickPay',
      invoiceNumber: inv,
      postalCode: 'NULL',
      status: false,
      userEmailId: null
    }
    let errMsg = "INVOICE_DOES_NOT_EXIST";
    let tit = "PAY_FREIGHT_INTERNATIONAL_INVOICES";
    this.obppQuickPayService.getAccountInfoByNumber(accountNumber, inv.toLowerCase(), 'postalCode').subscribe(res => {
      this.isLoading = false;
      if (res.serviceResponse.type == "success") {
        this.secAuditData.status = true;
        this.obppQuickPayService.quickPayData = res.serviceResponse;
        if(res.serviceResponse.object.obppInvoices.length <= 0) {
          this.openModal(tit, "NO_OPEN_INVOICES_TEXT", null, "NO", "warning");
        } else {
          this.successAction.emit(res.serviceResponse);
          this.activeModal.close("OK");
        }
        
        this.securityAuditService(this.secAuditData);
        //Route to the new page GRID
        this.route.navigateByUrl("/quickpay");
      } else {
        this.secAuditData.status = false;
        this.securityAuditService(this.secAuditData);
        this.openModal(tit, errMsg, null, "NO", "warning");
      }
    }, e => {
      console.log("error", e);
      this.isLoading = false;
    })
  }

  securityAuditService(data: any) {
    this.obppQuickPayService.getQuickPaySecurityAudit(data).subscribe(res => {
      console.log("Security Adit Log success");
    }, error => {
      console.log("Error", error);
    })
  }


  openModal(title: string, errorMsg: string, firstButtonLabel: any, secondButtonLabel: any, modalType: string) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;

    modalRef.componentInstance.successAction.subscribe(($e: any) => {
      console.log($e);
    });

    modalRef.componentInstance.cancelAction.subscribe(($e: any) => {
      console.log($e);
    })
  }

  quickPayFunction() {
    let isPostalCheckNeeded = true;
    let cPcode = "^[a-zA-Z]{1}\\d{1}[a-zA-Z]{1} *\\d{1}[a-zA-Z]{1}\\d{1}$";
    let uPcode = "^\\d{5}(-\\d{4})?$";
    if (this.validateFields()) {
      let inv = this.QuickPayInvoiceForm.get('invoiceNumber')?.value;
      if (!inv.match(cPcode) && !inv.match(uPcode)) {
        isPostalCheckNeeded = false;
      }
      let accountNumber = this.QuickPayInvoiceForm.get("accountNumber")?.value;
      this.isLoading = true;
      this.obppAuthService.getAccountSummary(accountNumber).subscribe(data => {
        console.log(data);
        let errMsg = "INVOICE_DOES_NOT_EXIST";
        let tit = "PAY_FREIGHT_INTERNATIONAL_INVOICES";
        this.isLoading = false;
        if (data.serviceResponse.type == "success") {
          if (isPostalCheckNeeded) {
            let pc = inv;
            pc = pc.length > 6 ? pc : pc.substr(0, 3) + " " + pc.substr(3, pc.length);
            //Below is the code to validate the postal code
            if (pc == data.serviceResponse.object.accountBillingPostcode) {
              this.getQuickPayData(accountNumber, pc.replace(/ /g, ''));
            } else {
              this.openModal(tit, errMsg, null, "NO", "warning");
            }
          } else {
              this.getQuickPayData(accountNumber, inv.replace(/ /g, ''));
          }

        } else {
          this.openModal(tit, errMsg, null, "NO", "warning");
        }




      }, err => {
        console.log(err);
        this.isLoading = false;
      })
    }
  }

}
import { Component, OnInit } from '@angular/core';
import { OBPPLoginService } from 'src/app/services/login-page/login-page.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OBPPModalDialogComponent } from '../../error-component/modal-dialog/modal-dialog.component';
import { OBPPQuickPayService } from 'src/app/services/quick-pay/quick-pay.service';
import { OBPPLoaderComponent } from 'src/app/common/shared/loader-component/obpp-loader.component';
import { QuickPaySecurityAudit } from 'src/app/models/new-user-registration.model';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';

@Component({
  selector: 'obpp-quick-pay-anonymous',
  templateUrl: './obpp-quick-pay.component.html',
  styleUrls: ['./obpp-quick-pay.component.scss'],
})
export class OBPPQuickPayAnonymousComponent implements OnInit {
  content?: string;

  username: string = '';
  pwd: string = '';

  QuickPayForm: FormGroup;
  isDuplicatePay = false;
  secAuditData?: QuickPaySecurityAudit | any;

  constructor(
    private obppAuthService: OBPPLoginService,
    private obppQuickPayService: OBPPQuickPayService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private route: Router,
    private dataSharingService: DataSharingService
  ) {
    this.QuickPayForm = this.fb.group({
      accountNumber: ['', Validators.required],
      invoiceNumber: ['', Validators.required],
    });
  }

  ngOnInit() {
    console.log('Quick Pay Component');
  }

  validateFields() {
    this.QuickPayForm.get('accountNumber')?.markAsDirty();
    this.QuickPayForm.get('invoiceNumber')?.markAsDirty();

    if (this.QuickPayForm.valid) {
      return true;
    }
    return false;
  }

  onSubmit() {
    let isPostalCheckNeeded = false;
    if (this.validateFields()) {
      let inv = this.QuickPayForm.get('invoiceNumber')?.value;
      let cPcode = '^[a-zA-Z]{1}\\d{1}[a-zA-Z]{1} *\\d{1}[a-zA-Z]{1}\\d{1}$';
      let uPcode = '^\\d{5}(-\\d{4})?$';
      if (inv.match(cPcode) || inv.match(uPcode)) {
        isPostalCheckNeeded = true;
      }
      let accountNumber = this.QuickPayForm.get('accountNumber')?.value;
      this.dataSharingService.IsLoadingEnabled.next(true);
      this.obppAuthService.getAccountSummary(accountNumber).subscribe(
        (data) => {
          let errMsg = 'QUICK_PAY_ERROR';
          let tit = 'QUICK_PAY_TEXT';
          this.dataSharingService.IsLoadingEnabled.next(false);
          if (data.serviceResponse.type == 'success') {
            if (isPostalCheckNeeded) {
              let pc = inv;
              pc =
                pc.length > 6
                  ? pc
                  : pc.substr(0, 3) + ' ' + pc.substr(3, pc.length);
              //Below is the code to validate the postal code
              if (
                pc.replace(/ /g, '').toLowerCase() ==
                data.serviceResponse.object.accountBillingPostcode
                  .replace(/ /g, '')
                  .toLowerCase()
              ) {
                this.getQuickPayData(
                  accountNumber,
                  pc.replace(/ /g, ''),
                  'postalCode'
                );
              } else {
                this.openModal(tit, errMsg, 'OK', null, 'warning');
              }
            } else {
              this.getQuickPayData(
                accountNumber,
                inv.replace(/ /g, ''),
                'invoiceNumber'
              );
            }
          } else {
            this.openModal(tit, errMsg, 'OK', null, 'warning');
          }
        },
        (err) => {
          this.dataSharingService.IsLoadingEnabled.next(false);
        }
      );
    }
  }

  openModal(
    title: string,
    errorMsg: string,
    firstButtonLabel: any,
    secondButtonLabel: any,
    modalType: string
  ) {
    const modalRef = this.modalService.open(OBPPModalDialogComponent);
    modalRef.componentInstance.my_modal_title = title;
    modalRef.componentInstance.my_modal_content = errorMsg;
    modalRef.componentInstance.firstButtonLabel = firstButtonLabel;
    modalRef.componentInstance.secondButtonLabel = secondButtonLabel;
    modalRef.componentInstance.modalType = modalType;
  }

  getQuickPayData(accountNumber: string, inv: string, type: string) {
    this.dataSharingService.IsLoadingEnabled.next(true);
    this.secAuditData = {
      accountNumber: accountNumber,
      attemptEntryPage: 'QuickPay',
      invoiceNumber: inv,
      postalCode: 'NULL',
      status: false,
      userEmailId: null,
    };
    let errMsg = 'QUICK_PAY_ERROR';
    let tit = 'QUICK_PAY_TEXT';
    this.obppQuickPayService
      .getAccountInfoByNumber(accountNumber, inv, type)
      .subscribe(
        (res) => {
          this.dataSharingService.IsLoadingEnabled.next(false);
          if (res.serviceResponse.type == 'success') {
            this.secAuditData.status = true;
            this.obppQuickPayService.quickPayData = res.serviceResponse;
            this.obppQuickPayService.selectedInvoice = inv;
            this.securityAuditService(this.secAuditData);
            this.route.navigateByUrl('/quickpay');
            this.dataSharingService.isRefreshRequired.next('quickpay');
          } else {
            this.secAuditData.status = false;
            this.securityAuditService(this.secAuditData);
            this.openModal(tit, errMsg, 'OK', null, 'warning');
          }
        },
        (e) => {
          this.dataSharingService.IsLoadingEnabled.next(false);
        }
      );
  }

  securityAuditService(data: any) {
    this.obppQuickPayService.getQuickPaySecurityAudit(data).subscribe(
      (res) => {
        console.log('Security Adit Log success');
      },
      (error) => {
        console.log('Error', error);
      }
    );
  }
}

<div class="modal-header" [ngClass]="modalType == 'error' ? 'error' : modalType == 'warning' ? 'warning' : 'success'">
  <h3 class="modal-title w-100 text-center">{{my_modal_title | translate}}</h3>
  <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body">
  <p class="card-text">{{'PAY_FOR_SINGLE_INVOICE_KEY' | translate }}</p>

  <form [formGroup]="QuickPayInvoiceForm">


    <div class="form-group input-group mb-3">
        <input type="text" class="form-control" formControlName="accountNumber" minlength="2" maxlength="12"
            placeholder="{{'ACCOUNT_NUMBER_TEXT' | translate}}" aria-describedby="basic-addon1" (input)="checkDuplicateAccount()">
            <div class="w-100" *ngIf="QuickPayInvoiceForm.get('accountNumber')?.invalid && QuickPayInvoiceForm.get('accountNumber')?.dirty">
                <span class="error-block">{{ 'INVALID_ACCOUNT_NUMBER_TEXT' | translate }}</span>
            </div>
    </div>
    <div class="form-group input-group mb-3">
        <p style="margin-bottom:0px !important" class="w-100 text-start">{{ 'VERIFIACTION_QUICK_PAY' | translate }}
            <img class="icon" src="assets/images/icon-tooltip.png"
                ngbTooltip="{{ 'TOOLTIP_QP_PC_INVOICE' | translate}}">
        </p>
        <input type="text" class="form-control" formControlName="invoiceNumber" maxlength="16" 
            placeholder="{{'INVOICE_NUMBER_POSTAL_CODE_TEXT' | translate}}"
            style="border-radius: 0.25rem !important;" aria-describedby="basic-addon1">
            <div class="w-100" *ngIf="QuickPayInvoiceForm.get('invoiceNumber')?.invalid && QuickPayInvoiceForm.get('invoiceNumber')?.dirty">
                <span class="error-block">{{ 'INVALID_INVOICE_NUMBER_POSTAL_CODE_TEXT' | translate }}</span>
            </div>
            <div class="w-100" *ngIf="isDuplicatePay">
                <span class="error-block">{{ 'DUPLICATE_ACCOUNT_QUICKPAY' | translate }}</span>
            </div>
    </div>
    </form>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button [disabled]="QuickPayInvoiceForm.invalid" type="button" *ngIf="firstButtonLabel" class="btn btn-primary btn-block primary-btn"
    (click)="successActionFunc()">{{firstButtonLabel | translate}}</button>
  <button type="button" *ngIf="secondButtonLabel" class="btn btn-primary cta-blue info-btn"
    (click)="cancelActionFunc()">{{secondButtonLabel | translate}}</button>
</div>
<div>
    <div class="modal-header text-center">
        <h4 class="modal-title">{{'REM_CRDT_CONF_TEXT' | translate}}</h4>
    </div>
    <div class="modal-body text-center">{{'REMOVE_CRDT_SURE_CONF_TEXT' | translate}}</div>
    <div class="modal-footer d-flex justify-content-center">
        <button class="btn btn-primary"  (click)="populateAccountAndConfirmRemove()">{{'REMOVE_CRDT_CONF_TEXT' | translate}}</button>
	
        <button class="btn btn-primary cta-blue info-btn" (click)="cancelAccountRemoval()">{{'CANCEL' | translate}}</button>
	
    </div>
</div>